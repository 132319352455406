import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {UserStateService} from '../../services/user-state.service';

@Component({
    selector: 'ef-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
    number = 0;
    interval: number;
    faFolderOpen = faFolderOpen;

    constructor(
        public router: Router,
        private userStateService: UserStateService
    ) {
        if (!userStateService.getUser()) {
            this.router.navigateByUrl('/auth/404');
        }
        this.number = 10;
        this.interval = setInterval(() => {
            this.number--;
            if (this.number <= 0) {
                clearInterval(this.interval);
                this.router.navigateByUrl('/dashboard');
            }
        }, 1000) as any as number;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }


}
