import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsModalComponent} from './news-modal/news-modal.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {FlexModule} from '@angular/flex-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
    declarations: [NewsModalComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatExpansionModule,
        FlexModule,
        MatButtonModule,
    ],
})
export class NewsModalModule {
}
