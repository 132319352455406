import {
    Component,
    ComponentFactoryResolver,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {navigation, superAdminNavigation, userNavigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationTurkish} from 'app/navigation/i18n/tr';
import {FuseConfig} from '../@fuse/types';
import {DateAdapter} from '@angular/material/core';
import {UserStateService} from './services/user-state.service';
import {faDraftingCompass, faFlask} from '@fortawesome/free-solid-svg-icons';
import {AnalyticService} from './services/analytic.service';
import {SettingsService} from './swagger';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import jwtDecode, {JwtPayload} from "jwt-decode";
import * as moment from 'moment';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('cookieDisclaimerContainer', {read: ViewContainerRef}) cookieDisclaimerContainer: ViewContainerRef;
    fuseConfig: FuseConfig;
    navigation: any;


    // Private
    private _unsubscribeAll: Subject<any>;
    private addedPlantProtection = false;
    private addedProjectFibl = false;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private userState: UserStateService,
        private _platform: Platform,
        private dateAdapter: DateAdapter<any>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private analyticsService: AnalyticService,
        private settingsService: SettingsService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (localStorage.getItem('token')) {
            const tokenContent = jwtDecode(localStorage.getItem('token')) as JwtPayload;
            if (moment(tokenContent.exp * 1000).isBefore()) {
                localStorage.removeItem('token');
            }
        }


        this.settingsService.getSettingByKey('MAINTENANCE').subscribe(val => {
            if (parseInt(val.value, 10) && !localStorage.getItem('SKIP_MAINTENANCE')) {
                this.router.navigateByUrl('/auth/wartungsarbeiten').then(val => {
                    this.router.events.pipe(filter(nav => nav instanceof NavigationEnd)).subscribe(val => {
                        this.router.navigateByUrl('/auth/wartungsarbeiten');
                    });
                });
            }
        });
        this.analyticsService.start();
        this.dateAdapter.setLocale('de');
        this.dateAdapter.getFirstDayOfWeek = () => 1;
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);
        this._fuseNavigationService.register('user', userNavigation);
        this._fuseNavigationService.register('superadmin', superAdminNavigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');



        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);


        this.userState.userObservable.pipe(
            filter(val => !!val)
        ).subscribe(user => {
            if (user.userrole === 'member') {
                this._fuseNavigationService.setCurrentNavigation('user');
            } else if (user.userrole === 'admin') {
                this._fuseNavigationService.setCurrentNavigation('main');
            } else if (user.userrole === 'superadmin') {
                this._fuseNavigationService.setCurrentNavigation('superadmin');
            }
            if (user.cookieAccepted === null) {
                import('./elements/cookie-banner/cookie-banner/cookie-banner.module').then(module => {
                    import('./elements/cookie-banner/cookie-banner.component').then(CookieBannerComponent => {
                        const resolver = this.componentFactoryResolver.resolveComponentFactory(CookieBannerComponent.CookieBannerComponent);
                        this.cookieDisclaimerContainer.clear();
                        this.cookieDisclaimerContainer.createComponent(resolver);
                    });
                });
            } else {
                this.cookieDisclaimerContainer.clear();
            }
        });


        this.userState.enterpriseObservable
            .subscribe(ent => {
                if (ent?.projectBe && this.addedPlantProtection === false) {
                    this.addedPlantProtection = true;
                    const schnittstellen = navigation[0].children.find(nav => nav.id === 'Schnittstellen');
                    schnittstellen.children.splice(0, 0, {
                        id: 'BernerPflanzenschutzprojekt',
                        title: 'Pflanzenschutzprojekt (BE)',
                        type: 'item',
                        icon: faFlask,
                        url: '/schnittstelle/bern',
                    });
                    const superAdmin = superAdminNavigation[0].children.find(nav => nav.id === 'Schnittstellen');
                    superAdmin.children.splice(0, 0, {
                        id: 'BernerPflanzenschutzprojekt',
                        title: 'Pflanzenschutzprojekt (BE)',
                        type: 'item',
                        icon: faFlask,
                        url: '/schnittstelle/bern',
                    });
                } else {
                    // TODO Pflanzenschutzprojekt wieder ausblenden
                }

                if (ent?.projectFibl && this.addedProjectFibl === false) {
                    this.addedProjectFibl = true;
                    const schnittstellen = navigation[0].children.find(nav => nav.id === 'Schnittstellen');
                    schnittstellen.children.splice(0, 0, {
                        id: 'FiBLExport',
                        title: 'FiBL Export',
                        type: 'item',
                        icon: faDraftingCompass,
                        url: '/fibl/export',
                    });
                    const superAdmin = superAdminNavigation[0].children.find(nav => nav.id === 'Schnittstellen');
                    superAdmin.children.splice(0, 0, {
                        id: 'FiBLExport',
                        title: 'FiBL Export',
                        type: 'item',
                        icon: faDraftingCompass,
                        url: '/fibl/export',
                    });
                } else {
                    // TODO FIBL wieder ausblenden
                }
            });

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;
                this.fuseConfig.layout.footer.hidden = true;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
