export enum ModalListEnum {
    addCulture,
    importListedCulture,
    addParcel,
    feedback,
    addMachine,
    importFertilizerTable,
    fertilizerForm,
    buyFertilizerForm,
    addRepair,
    addClient,
    closingBalance,
    addRental,
    importHerbicide,
    addHerbicide,
    migrateCulturesToTimeline,
    setStandardPrice,
    setAsProduct,
    distributeRestHerbicide,
    buyHerbicide,
    distributeFertilizer,
    setDefaultPriceFertilizer,
    dilutionForm,
    editPurchase,
    applicationsOfMachine,
    videoHelp,
    setProfilePicture,
    migrateParcels,
    moveCultureToNextYear,
    assignParcelModal,
    varietyForm,
    editEnterprise,
    addCoupon,
    addShareForEnterprise,
    unsubcribe,
    modifyAgent,
    newsForm,
    newsletterForm,
    fiblHarvestModal,
    openUploadFileModal,
    editFile,
    buyFileLicenseModal,
    fiblTillagModal,
    migrateWithMap,
    exportGeodataWizard,
    addCultureNew,
    importListedCultureForm,
}

export const ModalList = [
    {
        type: ModalListEnum.addCulture,
        name: 'addCultureForm',
        paramsToRemove: ['cultureId'],
        moduleToLoad: () => import('../pages/parcels/shared-parcels/shared-parcels.module').then(m => m.SharedParcelsModule),
        load: () => import('../pages/parcels/shared-parcels/culture-form/culture-form.component')
            .then(v => v.CultureFormComponent)
    },
    {
        type: ModalListEnum.migrateCulturesToTimeline,
        name: 'migrate-culture',
        paramsToRemove: ['cultureId'],
        moduleToLoad: () => import('../elements/migrate-culture-to-timeline/migrate-culture-to-timeline.module').then(m => m.MigrateCultureToTimelineModule),
        load: () => import('../elements/migrate-culture-to-timeline/migrate-culture-to-timeline/migrate-culture-to-timeline.component')
            .then(v => v.MigrateCultureToTimelineComponent)
    },
    {
        type: ModalListEnum.addCultureNew,
        name: 'addCultureFormNew',
        paramsToRemove: ['cultureId'],
        moduleToLoad: () => import('../pages/parcels/shared-parcels/shared-parcels.module').then(m => m.SharedParcelsModule),
        load: () => import('../pages/parcels/shared-parcels/culture-form-new/culture-form-new.component')
            .then(v => v.CultureFormNewComponent)
    },
    {
        type: ModalListEnum.assignParcelModal,
        name: 'parzelle-zuweisen',
        load: () => import('../pages/parcels/shared-parcels/assign-parcel-modal/assign-parcel-modal.component').then(v => v.AssignParcelModalComponent)
    },
    {
        type: ModalListEnum.editFile,
        name: 'datei-bearbeiten',
        load: () => import('../elements/file-uploader/edit-file-modal/edit-file-modal.component').then(v => v.EditFileModalComponent)
    },
    {
        type: ModalListEnum.buyFileLicenseModal,
        name: 'datei-lizenz-kaufen',
        load: () => import('../elements/file-uploader/buy-file-module-license/buy-file-module-license.component').then(v => v.BuyFileModuleLicenseComponent)
    },
    {
        type: ModalListEnum.fiblHarvestModal,
        name: 'ernte-fibl',
        load: () => import('../pages/cultures/shared-culture/fibl-harvest-modal/fibl-harvest-modal.component').then(v => v.FiblHarvestModalComponent)
    },
    {
        type: ModalListEnum.fiblTillagModal,
        name: 'saat-fibl',
        load: () => import('../pages/cultures/shared-culture/fibl-tillag-modal/fibl-tillag-modal.component').then(v => v.FiblTillagModalComponent)
    },
    {
        type: ModalListEnum.openUploadFileModal,
        name: 'datei-hochladen',
        load: () => import('../pages/file-management/shared-file-management/upload-culture-file-modal/upload-culture-file-modal.component').then(v => v.UploadCultureFileModalComponent)
    },
    {
        type: ModalListEnum.modifyAgent,
        name: 'modify-agent',
        load: () => import('../pages/admin/agent-modal/agent-modal.component').then(v => v.AgentModalComponent)
    },
    {
        type: ModalListEnum.newsletterForm,
        name: 'modify-newsletter',
        load: () => import('../pages/admin/newsletter-form/newsletter-form.component').then(v => v.NewsletterFormComponent)
    },
    {
        type: ModalListEnum.addShareForEnterprise,
        name: 'unternehmensfreigabe-hinzufuegen',
        load: () => import('../pages/enterprises/shared-enterprise/add-enterprise-share/add-enterprise-share.component').then(v => v.AddEnterpriseShareComponent)
    },
    {
        type: ModalListEnum.newsForm,
        name: 'news-form',
        load: () => import('../pages/admin/news-modal/news-modal-form.component').then(v => v.NewsModalFormComponent)
    },
    {
        type: ModalListEnum.importListedCulture,
        name: 'import-listed-culture',
        load: () => import('../pages/listed-culture-setting/shared-listed-culture/import-listed-culture-modal/import-listed-culture-modal.component').then(v => v.ImportListedCultureModalComponent)
    },
    {
        type: ModalListEnum.importListedCultureForm,
        name: 'import-listed-culture-form',
        load: () => import('../pages/listed-culture-setting/shared-listed-culture/import-listed-culture-form-modal/import-listed-culture-form-modal.component').then(v => v.ImportListedCultureFormModalComponent)
    },
    {
        type: ModalListEnum.exportGeodataWizard,
        name: 'export-geodata',
        load: () => import('../elements/export-geodata-wizard/export-geodata-wizard-modal/export-geodata-wizard-modal.component').then(v => v.ExportGeodataWizardModalComponent)
    },
    {
        type: ModalListEnum.addCoupon,
        name: 'gutschein-hinzufuegen',
        load: () => import('../pages/enterprises/shared-enterprise/enter-coupon-modal/enter-coupon-modal.component').then(v => v.EnterCouponModalComponent)
    },
    {
        type: ModalListEnum.editEnterprise,
        name: 'unternehmen-bearbeiten',
        load: () => import('../pages/enterprises/enterprise-form/enterprise-form.component').then(v => v.EnterpriseFormComponent)
    },
    {
        type: ModalListEnum.closingBalance,
        name: 'schlussbestand-anpassen',
        load: () => import('../pages/myproducts/shared-myproduct/change-closing-balance/change-closing-balance.component').then(v => v.ChangeClosingBalanceComponent)
    },
    {
        type: ModalListEnum.unsubcribe,
        name: 'vertrag-aufloesen',
        load: () => import('../pages/enterprises/shared-enterprise/unsubscribe-modal/unsubscribe-modal.component').then(v => v.UnsubscribeModalComponent)
    },
    {
        type: ModalListEnum.varietyForm,
        name: 'sorte-formular',
        load: () => import('../pages/variety/variety-form/variety-form.component').then(v => v.VarietyFormComponent)
    },
    {
        type: ModalListEnum.setProfilePicture,
        name: 'profilbild-bearbeiten',
        load: () => import('../pages/employee/shared-employee/set-profile-picture-modal/set-profile-picture-modal.component').then(v => v.SetProfilePictureModalComponent)
    },
    {
        type: ModalListEnum.moveCultureToNextYear,
        name: 'kultur-verschieben',
        load: () => import('../pages/cultures/shared-culture/move-culture/move-culture.component').then(v => v.MoveCultureComponent)
    },
    {
        type: ModalListEnum.migrateParcels,
        name: 'migrateParcels',
        load: () => import('../pages/cultures/culture-migration/culture-migration-wizard/culture-migration-wizard.component').then(v => v.CultureMigrationWizardComponent)
    },
    {
        type: ModalListEnum.migrateWithMap,
        name: 'map-migration',
        load: () => import('../pages/cultures/culture-migration/culture-migration-map-wizard/culture-migration-map-wizard.component').then(v => v.CultureMigrationMapWizardComponent)
    },
    {
        type: ModalListEnum.videoHelp,
        name: 'videoHelp',
        load: () => import('../pages/help/shared-help/video-help/video-help.component').then(v => v.VideoHelpComponent)
    },
    {
        type: ModalListEnum.dilutionForm,
        name: 'dilutionForm',
        load: () => import('../pages/myproducts/dilutation-form/dilutation-form.component').then(v => v.DilutationFormComponent)
    },
    {
        type: ModalListEnum.editPurchase,
        name: 'editPurchase',
        load: () => import('../pages/purchase/shared-purchase/edit-purchase-form/edit-purchase-form.component').then(v => v.EditPurchaseFormComponent)
    },
    {
        type: ModalListEnum.setDefaultPriceFertilizer,
        name: 'setDefaultPriceFertilizer',
        load: () => import('../pages/myproducts/set-default-price-fertilizer/set-default-price-fertilizer.component').then(v => v.SetDefaultPriceFertilizerComponent)
    },
    {
        type: ModalListEnum.distributeFertilizer,
        name: 'distributeFertilizer',
        load: () => import('../pages/myproducts/distribute-fertilizer/distribute-fertilizer.component').then(v => v.DistributeFertilizerComponent)
    },
    {
        type: ModalListEnum.distributeRestHerbicide,
        name: 'distributeRestHerbicide',
        load: () => import('../pages/herbicide/shared-herbicide/distribut-herbicide-modal/distribut-herbicide-modal.component').then(v => v.DistributHerbicideModalComponent)
    },
    {
        type: ModalListEnum.buyHerbicide,
        name: 'buyHerbicide',
        load: () => import('../pages/herbicide/shared-herbicide/buy-herbicide/buy-herbicide.component').then(v => v.BuyHerbicideComponent)
    },
    {
        type: ModalListEnum.setAsProduct,
        name: 'setAsProduct',
        load: () => import('../pages/herbicide/shared-herbicide/set-as-product/set-as-product.component').then(v => v.SetAsProductComponent)
    },
    {
        type: ModalListEnum.setStandardPrice,
        name: 'setStandardPrice',
        load: () => import('../pages/herbicide/shared-herbicide/add-standard-price-herbicide-form/add-standard-price-herbicide-form.component').then(v => v.AddStandardPriceHerbicideFormComponent)
    },
    {
        type: ModalListEnum.addHerbicide,
        name: 'addHerbicide',
        load: () => import('../pages/herbicide/shared-herbicide/add-herbicide-form/add-herbicide-form.component').then(v => v.AddHerbicideFormComponent)
    },
    {
        type: ModalListEnum.importHerbicide,
        name: 'importHerbicide',
        load: () => import('../pages/herbicide/shared-herbicide/import-herbicide-table/import-herbicide-table.component').then(v => v.ImportHerbicideTableComponent)
    },
    {
        type: ModalListEnum.addRental,
        name: 'addRental',
        load: () => import('../pages/rental/shared-rental/rental-form/rental-form.component').then(v => v.RentalFormComponent)
    },
    {
        type: ModalListEnum.addClient,
        name: 'addClient',
        load: () => import('../pages/customer/shared-customer/customer-form/customer-form.component').then(v => v.CustomerFormComponent)
    },
    {
        type: ModalListEnum.addRepair,
        name: 'addRepair',
        load: () => import('../pages/repair/shared-repair/repair-form/repair-form.component').then(v => v.RepairFormComponent)
    },
    {
        type: ModalListEnum.importFertilizerTable,
        name: 'importFertilizerTable',
        load: () => import('../pages/myproducts/import-fertilizer-table/import-fertilizer-table.component').then(v => v.ImportFertilizerTableComponent)
    },
    {
        type: ModalListEnum.addMachine,
        name: 'addMachine',
        load: () => import('../pages/machine/shared-machine/machine-form/machine-form.component').then(v => v.MachineFormComponent)
    },
    {
        type: ModalListEnum.fertilizerForm,
        name: 'fertilizer-form',
        load: () => import('../pages/myproducts/fertilizer-form/fertilizer-form.component').then(v => v.FertilizerFormComponent)
    },
    {
        type: ModalListEnum.buyFertilizerForm,
        name: 'buy-fertilizer-form',
        load: () => import('../pages/myproducts/buy-fertilizer-form/buy-fertilizer-form.component').then(v => v.BuyFertilizerFormComponent)
    },
    {
        type: ModalListEnum.addParcel,
        name: 'addParcelForm',
        paramsToRemove: ['parcelId'],
        load: () => import('../pages/parcels/shared-parcels/parcels-form/parcels-form.component').then(v => v.ParcelsFormComponent)
    },
    {
        type: ModalListEnum.feedback,
        name: 'feedback',
        load: () => import('../dialogs/feedback/feedback.component').then(v => v.FeedbackComponent)
    },/*
    {
        type: ModalListEnum.addMachine,
        name: 'addMachine',
        load: () => import('../pages/').then(v => v.FeedbackComponent)
    },*/
];

