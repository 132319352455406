import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {ElementsModule} from '../../../elements/elements.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HideOnReadOnlyModule} from '../../../directives/hide-on-read-only/hide-on-read-only.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        ElementsModule,
        FontAwesomeModule,
        HideOnReadOnlyModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {
}
