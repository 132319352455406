import {NgModule} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';

import {FuseSharedModule} from '@fuse/shared.module';

import {QuickPanelComponent} from 'app/layout/components/quick-panel/quick-panel.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {HideOnReadOnlyModule} from '../../../directives/hide-on-read-only/hide-on-read-only.module';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        FuseSharedModule,
        MatButtonModule,
        RouterModule,
        FontAwesomeModule,
        MatTooltipModule,
        HideOnReadOnlyModule
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule {
}
