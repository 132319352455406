export const environment = {
    production: true,
    hmr: false,
    api: 'https://staging-api.feldkalender.ch/api',
    backend: 'https://staging-api.feldkalender.ch',
    analytics: {
        trackingId: 'UA-65564847-3',
        cookieDomain: 'none',
        debug: true
    },
    firebase: {
        apiKey: 'AIzaSyCbQPPTdnU2mmcrNOhOkcsE06nwckE_vR8',
        authDomain: 'efeldkalender-staging.firebaseapp.com',
        projectId: 'efeldkalender-staging',
        storageBucket: 'efeldkalender-staging.appspot.com',
        messagingSenderId: '549033925160',
        appId: '1:549033925160:web:25ac377d5532f84312731d'
    }
};
