import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {catchError, tap} from 'rxjs/operators';
import {MessageDto} from '../swagger';
import {UserStateService} from '../services/user-state.service';
import TypeEnum = MessageDto.TypeEnum;

@Injectable()
export class MessageInterceptor implements HttpInterceptor {
    lastTimeMessageDisplay: number;

    constructor(
        private toastrService: ToastrService,
        private userStateService: UserStateService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.checkMessage(evt);
                }
            }),
            catchError((error: any) => {
                this.checkMessage(error);
                return throwError(error);
            })
        );
    }

    private checkMessage(evt: HttpResponse<any>) {
        if ((evt as any)?.error?.reason === 'TOKEN_INVALID') {
            // TODO FIX
            // this.userStateService.logout();
        }
        if (evt.body && evt.body.title && evt.body.body) {
            if (evt.body && evt.body.type === TypeEnum.Success) {
                this.toastrService.success(evt.body.body, evt.body.title, {
                    positionClass: 'toast-top-right',
                    timeOut: evt.body.duration
                });
            } else if (evt.body && evt.body.type === TypeEnum.Danger) {
                this.toastrService.error(evt.body.body, evt.body.title, {
                    positionClass: 'toast-top-right',
                    timeOut: evt.body.duration
                });
            } else if (evt.body && evt.body.type === TypeEnum.Warning) {
                this.toastrService.warning(evt.body.body, evt.body.title, {
                    positionClass: 'toast-top-right',
                    timeOut: evt.body.duration
                });
            } else if (evt.body && evt.body.type === TypeEnum.Info) {
                this.toastrService.info(evt.body.body, evt.body.title, {
                    positionClass: 'toast-top-right',
                    timeOut: evt.body.duration
                });
            }
        } else if (evt.headers.get('message-title')) {
            if (evt.headers && evt.headers.get('message-type') === TypeEnum.Success) {
                this.toastrService.success(evt.headers.get('message-body'), evt.headers.get('message-title'), {
                    positionClass: 'toast-top-right',
                    timeOut: +evt.headers.get('message-duration')
                });
            } else if (evt.headers && evt.headers.get('message-type') === TypeEnum.Danger) {
                this.toastrService.error(evt.headers.get('message-body'), evt.headers.get('message-title'), {
                    positionClass: 'toast-top-right',
                    timeOut: +evt.headers.get('message-duration')
                });
            } else if (evt.headers && evt.headers.get('message-type') === TypeEnum.Warning) {
                this.toastrService.warning(evt.headers.get('message-body'), evt.headers.get('message-title'), {
                    positionClass: 'toast-top-right',
                    timeOut: +evt.headers.get('message-duration')
                });
            } else if (evt.headers && evt.headers.get('message-type') === TypeEnum.Info) {
                this.toastrService.info(evt.headers.get('message-body'), evt.headers.get('message-title'), {
                    positionClass: 'toast-top-right',
                    timeOut: +evt.headers.get('message-duration')
                });
            }
        }
    }
}
