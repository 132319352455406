import {FuseNavigation} from '@fuse/types';
import {
    faAddressBook,
    faArchive,
    faBalanceScale,
    faBug,
    faBullhorn,
    faChartBar,
    faClipboard,
    faCodeBranch,
    faCog,
    faCogs,
    faEdit,
    faEnvelope,
    faEnvelopeOpenText,
    faExchangeAlt,
    faExclamation,
    faFlask,
    faFunnelDollar,
    faList,
    faMapMarkerAlt,
    faMoneyBill, faMound,
    faPalette,
    faPrint,
    faQuestion,
    faSeedling,
    faShoppingCart,
    faSync,
    faTachometerAlt,
    faTasks,
    faTractor,
    faTrailer,
    faUserClock,
    faUserCog,
    faUserFriends,
    faUsers, faVials,
    faWrench
} from '@fortawesome/free-solid-svg-icons';

export const userNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'eFeldkalender',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: faTachometerAlt,
                url: '/dashboard',
            },
            {
                id: 'culturesheet',
                title: 'Kulturenblätter',
                type: 'item',
                icon: faMapMarkerAlt,
                url: '/cultures/culturesheet',
            },
            {
                id: 'Auswertungen',
                title: 'Auswertungen',
                type: 'collapsable',
                icon: faChartBar,
                children: [
                    {
                        id: 'Massnahmen',
                        title: 'Massnahmen',
                        type: 'item',
                        icon: faList,
                        url: '/reports/massnahmen',
                    },
                ]
            },
            {
                id: 'auftraege',
                title: 'Aufträge',
                type: 'collapsable',
                icon: faTasks,
                children: [
                    {
                        id: 'auftraege_mitrabeiter',
                        title: 'Meine Aufträge',
                        icon: faUserClock,
                        type: 'item',
                        url: '/aufgaben/meine',
                    },
                    {
                        id: 'offene_aufgaben',
                        title: 'Auftragsübersicht',
                        type: 'item',
                        icon: faTasks,
                        url: '/aufgaben/liste',
                    }
                ],
            },
            {
                id: 'Dateiverwaltung',
                title: 'Dateiverwaltung',
                type: 'item',
                icon: faArchive,
                url: '/dateien/liste',
            },
            {
                id: 'News',
                title: 'News',
                type: 'item',
                icon: faBullhorn,
                url: '/news/uebersicht',
            },
            {
                id: 'Hilfe',
                title: 'Hilfe',
                type: 'item',
                icon: faQuestion,
                url: '/hilfe/uebersicht',
            },
            {
                id: 'Support',
                title: 'Support',
                type: 'item',
                icon: faEnvelope,
                url: '/openModal--feedback'
            },
            {
                id: 'Version',
                title: 'Version',
                type: 'item',
                icon: faCodeBranch,
                url: '/version/neuigkeiten'
            },
        ]
    }
];

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'eFeldkalender',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: faTachometerAlt,
                url: '/dashboard',
            },
            {
                id: 'culturesheet',
                title: 'Kulturenblätter',
                type: 'item',
                icon: faMapMarkerAlt,
                url: '/cultures/culturesheet',
            },
            {
                id: 'notizen',
                title: 'Notizen',
                type: 'item',
                icon: faClipboard,
                url: '/notizen',
            },
            {
                id: 'auftraege',
                title: 'Aufträge',
                type: 'collapsable',
                icon: faTasks,
                children: [
                    {
                        id: 'auftraege_mitrabeiter',
                        title: 'Meine Aufträge',
                        icon: faUserClock,
                        type: 'item',
                        url: '/aufgaben/meine',
                    },
                    {
                        id: 'offene_aufgaben',
                        title: 'Auftragsübersicht',
                        type: 'item',
                        icon: faTasks,
                        url: '/aufgaben/liste',
                    }
                ],
            },
            {
                id: 'grundlagendaten',
                title: 'Grundlagendaten',
                type: 'collapsable',
                icon: faCogs,
                children: [
                    {
                        id: 'mitarbeiter',
                        title: 'Mitarbeiter',
                        icon: faUserFriends,
                        type: 'item',
                        url: '/benutzer/liste',
                    },
                    {
                        id: 'parzellen_kulturen',
                        title: 'Parzellen/Kulturen',
                        type: 'item',
                        icon: faMapMarkerAlt,
                        url: '/parcels/culturesIndex',
                    },
                    {
                        id: 'nährstoffbeschränkungen',
                        title: 'Nährstoffbeschränkungen',
                        type: 'item',
                        icon: faExclamation,
                        url: '/unternehmen/maxOverview',
                    },
                    {
                        id: 'Textvorlagen',
                        title: 'Textvorlagen',
                        type: 'item',
                        icon: faEdit,
                        url: '/textvorlagen/anzeige',
                    },
                    {
                        id: 'Sorten',
                        title: 'Sorten',
                        type: 'item',
                        icon: faSeedling,
                        url: '/sorten/anzeige',
                    },
                    {
                        id: 'Color',
                        title: 'Farbschema',
                        type: 'item',
                        icon: faPalette,
                        url: '/gelistete-kulturen/farbschema',
                    },
                    {
                        id: 'Kultureinstellung',
                        title: 'Kultureinstellungen',
                        type: 'item',
                        icon: faMound,
                        url: '/gelistete-kulturen/verwalten',
                    }
                ]
            },
            {
                id: 'Produkte',
                title: 'Produkte',
                type: 'collapsable',
                icon: faFlask,
                children: [
                    {
                        id: 'Düngemittel',
                        title: 'Düngemittel',
                        icon: faFlask,
                        type: 'item',
                        url: '/myproducts/duenger',
                    },
                    {
                        id: 'Pflanzenschutzmittel',
                        title: 'Pflanzenschutzmittel',
                        type: 'item',
                        icon: faFlask,
                        url: '/produkte/pflanzenschutz',
                    },
                    {
                        id: 'Zukäufe/Verluste',
                        title: 'Zukäufe/Verluste',
                        type: 'item',
                        icon: faShoppingCart,
                        url: '/myproducts/importliste',
                    }
                ]
            },
            {
                id: 'Maschinen',
                title: 'Maschinen',
                type: 'collapsable',
                icon: faTractor,
                children: [
                    {
                        id: 'Maschinen',
                        title: 'Maschinen',
                        icon: faTractor,
                        type: 'item',
                        url: '/maschine',
                    },
                    {
                        id: 'Reperaturjournal',
                        title: 'Reperaturjournal',
                        type: 'item',
                        icon: faWrench,
                        url: '/reparatur',
                    },
                    {
                        id: 'Kunden',
                        title: 'Kunden',
                        type: 'item',
                        icon: faAddressBook,
                        url: '/kunden/',
                    },
                    {
                        id: 'Miete/Vermietung',
                        title: 'Miete/Vermietung',
                        type: 'item',
                        icon: faTrailer,
                        url: '/mietuebersicht',
                    }
                ]
            },
            {
                id: 'Schnittstellen',
                title: 'Schnittstellen',
                type: 'collapsable',
                icon: faExchangeAlt,
                children: [
                    {
                        id: 'JohnDeere',
                        title: 'JohnDeere',
                        type: 'item',
                        icon: faTractor,
                        url: '/john-deere/zentrum',
                    },
                    {
                        id: 'Trichogramma',
                        title: 'Trichogramma',
                        type: 'item',
                        icon: faBug,
                        url: '/trichogramma/uebersicht',
                    },
                ]
            },

            {
                id: 'Auswertungen',
                title: 'Auswertungen',
                type: 'collapsable',
                icon: faChartBar,
                children: [
                    {
                        id: 'Journal Düngemitteleinsatz',
                        title: 'Journal Düngemitteleinsatz',
                        icon: faBalanceScale,
                        type: 'item',
                        url: '/reports/journal-duenger',
                    },
                    {
                        id: 'Journal Pflanzenschutzmitteleinsatz',
                        title: 'Journal Pflanzenschutz',
                        icon: faVials,
                        type: 'item',
                        url: '/reports/journal-pflanzenschutzmittel',
                    },
                    {
                        id: 'Fruchtfolgerapport',
                        title: 'Fruchtfolgerapport',
                        type: 'item',
                        icon: faSync,
                        url: '/reports/fruchtfolgerapport',
                    },
                    {
                        id: 'Massnahmen',
                        title: 'Massnahmen',
                        type: 'item',
                        icon: faList,
                        url: '/reports/massnahmen',
                    },
                    {
                        id: 'Massnahmen je Maschine',
                        title: 'Massnahmen je Maschine',
                        type: 'item',
                        icon: faTractor,
                        url: '/reports/massnahmen-pro-maschine',
                    },
                    {
                        id: 'Kulturenauswertungen',
                        title: 'Kulturenauswertungen',
                        type: 'item',
                        icon: faMapMarkerAlt,
                        url: '/reports/kulturauswertungen',
                    },
                    {
                        id: 'Produkteauswertungen',
                        title: 'Produkteauswertungen',
                        type: 'item',
                        icon: faFlask,
                        url: '/reports/produkteauswertung',
                    }
                ]
            },
            {
                id: 'Dateiverwaltung',
                title: 'Dateiverwaltung',
                type: 'item',
                icon: faArchive,
                url: '/dateien/liste',
            },
            {
                id: 'Drucken',
                title: 'Drucken',
                type: 'item',
                icon: faPrint,
                url: '/reports/drucken',
            },
            {
                id: 'Kundencenter',
                title: 'Kundencenter',
                type: 'item',
                icon: faUserCog,
                url: '/unternehmen/kundencenter',
            },
            {
                id: 'News',
                title: 'News',
                type: 'item',
                icon: faBullhorn,
                url: '/news/uebersicht',
            },
            {
                id: 'Hilfe',
                title: 'Hilfe',
                type: 'item',
                icon: faQuestion,
                url: '/hilfe/uebersicht',
            },
            {
                id: 'Support',
                title: 'Support',
                type: 'item',
                icon: faEnvelope,
                url: '/openModal--feedback'
            },
            {
                id: 'Version',
                title: 'Version',
                type: 'item',
                icon: faCodeBranch,
                url: '/version/neuigkeiten'
            },
        ]
    }
];

export const superAdminNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'eFeldkalender',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: faTachometerAlt,
                url: '/dashboard',
            },
            {
                id: 'culturesheet',
                title: 'Kulturenblätter',
                type: 'item',
                icon: faMapMarkerAlt,
                url: '/cultures/culturesheet',
            },
            {
                id: 'notizen',
                title: 'Notizen',
                type: 'item',
                icon: faClipboard,
                url: '/notizen',
            },
            {
                id: 'auftraege',
                title: 'Aufträge',
                type: 'collapsable',
                icon: faTasks,
                children: [
                    {
                        id: 'auftraege_mitrabeiter',
                        title: 'Meine Aufträge',
                        icon: faUserClock,
                        type: 'item',
                        url: '/aufgaben/meine',
                    },
                    {
                        id: 'offene_aufgaben',
                        title: 'Auftragsübersicht',
                        type: 'item',
                        icon: faTasks,
                        url: '/aufgaben/liste',
                    }
                ],
            },
            {
                id: 'grundlagendaten',
                title: 'Grundlagendaten',
                type: 'collapsable',
                icon: faCogs,
                children: [
                    {
                        id: 'mitarbeiter',
                        title: 'Mitarbeiter',
                        icon: faUserFriends,
                        type: 'item',
                        url: '/benutzer/liste',
                    },
                    {
                        id: 'parzellen_kulturen',
                        title: 'Parzellen/Kulturen',
                        type: 'item',
                        icon: faMapMarkerAlt,
                        url: '/parcels/culturesIndex',
                    },
                    {
                        id: 'nährstoffbeschränkungen',
                        title: 'Nährstoffbeschränkungen',
                        type: 'item',
                        icon: faExclamation,
                        url: '/unternehmen/maxOverview',
                    },
                    {
                        id: 'Textvorlagen',
                        title: 'Textvorlagen',
                        type: 'item',
                        icon: faEdit,
                        url: '/textvorlagen/anzeige',
                    },
                    {
                        id: 'Sorten',
                        title: 'Sorten',
                        type: 'item',
                        icon: faSeedling,
                        url: '/sorten/anzeige',
                    },
                    {
                        id: 'Color',
                        title: 'Farbschema',
                        type: 'item',
                        icon: faPalette,
                        url: '/gelistete-kulturen/farbschema',
                    },
                    {
                        id: 'Kultureinstellung',
                        title: 'Kultureinstellungen',
                        type: 'item',
                        icon: faMound,
                        url: '/gelistete-kulturen/verwalten',
                    }
                ]
            },
            {
                id: 'Produkte',
                title: 'Produkte',
                type: 'collapsable',
                icon: faFlask,
                children: [
                    {
                        id: 'Düngemittel',
                        title: 'Düngemittel',
                        icon: faFlask,
                        type: 'item',
                        url: '/myproducts/duenger',
                    },
                    {
                        id: 'Pflanzenschutzmittel',
                        title: 'Pflanzenschutzmittel',
                        type: 'item',
                        icon: faFlask,
                        url: '/produkte/pflanzenschutz',
                    },
                    {
                        id: 'Zukäufe/Verluste',
                        title: 'Zukäufe/Verluste',
                        type: 'item',
                        icon: faShoppingCart,
                        url: '/myproducts/importliste',
                    }
                ]
            },
            {
                id: 'Maschinen',
                title: 'Maschinen',
                type: 'collapsable',
                icon: faTractor,
                children: [
                    {
                        id: 'Maschinen',
                        title: 'Maschinen',
                        icon: faTractor,
                        type: 'item',
                        url: '/maschine',
                    },
                    {
                        id: 'Reperaturjournal',
                        title: 'Reperaturjournal',
                        type: 'item',
                        icon: faWrench,
                        url: '/reparatur',
                    },
                    {
                        id: 'Kunden',
                        title: 'Kunden',
                        type: 'item',
                        icon: faAddressBook,
                        url: '/kunden/',
                    },
                    {
                        id: 'Miete/Vermietung',
                        title: 'Miete/Vermietung',
                        type: 'item',
                        icon: faTrailer,
                        url: '/mietuebersicht',
                    }
                ]
            },
            {
                id: 'Schnittstellen',
                title: 'Schnittstellen',
                type: 'collapsable',
                icon: faExchangeAlt,
                children: [
                    {
                        id: 'JohnDeere',
                        title: 'JohnDeere',
                        type: 'item',
                        icon: faTractor,
                        url: '/john-deere/zentrum',
                    },
                    {
                        id: 'Trichogramma',
                        title: 'Trichogramma',
                        type: 'item',
                        icon: faBug,
                        url: '/trichogramma/uebersicht',
                    },
                ]
            },

            {
                id: 'Auswertungen',
                title: 'Auswertungen',
                type: 'collapsable',
                icon: faChartBar,
                children: [
                    {
                        id: 'Journal Düngemitteleinsatz',
                        title: 'Journal Düngemitteleinsatz',
                        icon: faBalanceScale,
                        type: 'item',
                        url: '/reports/journal-duenger',
                    },
                    {
                        id: 'Journal Pflanzenschutzmitteleinsatz',
                        title: 'Journal Pflanzenschutz',
                        icon: faVials,
                        type: 'item',
                        url: '/reports/journal-pflanzenschutzmittel',
                    },
                    {
                        id: 'Fruchtfolgerapport',
                        title: 'Fruchtfolgerapport',
                        type: 'item',
                        icon: faSync,
                        url: '/reports/fruchtfolgerapport',
                    },
                    {
                        id: 'Massnahmen',
                        title: 'Massnahmen',
                        type: 'item',
                        icon: faList,
                        url: '/reports/massnahmen',
                    },
                    {
                        id: 'Massnahmen je Maschine',
                        title: 'Massnahmen je Maschine',
                        type: 'item',
                        icon: faTractor,
                        url: '/reports/massnahmen-pro-maschine',
                    },
                    {
                        id: 'Kulturenauswertungen',
                        title: 'Kulturenauswertungen',
                        type: 'item',
                        icon: faMapMarkerAlt,
                        url: '/reports/kulturauswertungen',
                    },
                    {
                        id: 'Produkteauswertungen',
                        title: 'Produkteauswertungen',
                        type: 'item',
                        icon: faFlask,
                        url: '/reports/produkteauswertung',
                    }
                ]
            },
            {
                id: 'Dateiverwaltung',
                title: 'Dateiverwaltung',
                type: 'item',
                icon: faArchive,
                url: '/dateien/liste',
            },
            {
                id: 'Drucken',
                title: 'Drucken',
                type: 'item',
                icon: faPrint,
                url: '/reports/drucken',
            },
            {
                id: 'Kundencenter',
                title: 'Kundencenter',
                type: 'item',
                icon: faUserCog,
                url: '/unternehmen/kundencenter',
            },
            {
                id: 'News',
                title: 'News',
                type: 'item',
                icon: faBullhorn,
                url: '/news/uebersicht',
            },
            {
                id: 'Hilfe',
                title: 'Hilfe',
                type: 'item',
                icon: faQuestion,
                url: '/hilfe/uebersicht',
            },
            {
                id: 'Support',
                title: 'Support',
                type: 'item',
                icon: faEnvelope,
                url: '/openModal--feedback'
            },
            {
                id: 'Version',
                title: 'Version',
                type: 'item',
                icon: faCodeBranch,
                url: '/version/neuigkeiten'
            },
            {
                id: 'Administrator',
                title: 'Administrator',
                type: 'collapsable',
                icon: faCog,
                children: [
                    {
                        id: 'Adminbereich',
                        title: 'Adminbereich',
                        type: 'item',
                        icon: faUsers,
                        url: 'admin/benutzer'
                    },
                    {
                        id: 'Zahlungen',
                        title: 'Zahlungen',
                        type: 'item',
                        icon: faMoneyBill,
                        url: 'admin/zahlungen'
                    },
                    {
                        id: 'Konfiguration',
                        title: 'Konfiguration',
                        type: 'item',
                        icon: faCogs,
                        url: 'admin/konfiguration'
                    },
                    {
                        id: 'Rabatte',
                        title: 'Rabatte',
                        type: 'item',
                        icon: faFunnelDollar,
                        url: 'admin/rabatte'
                    },
                    {
                        id: 'Newsletter',
                        title: 'Newsletter',
                        type: 'item',
                        icon: faEnvelopeOpenText,
                        url: 'admin/newsletter'
                    },
                    {
                        id: 'Statistik',
                        title: 'Statistik',
                        type: 'item',
                        icon: faChartBar,
                        url: 'admin/statistik'
                    },
                ]
            },

        ]
    }
];