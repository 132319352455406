/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { License } from './license';
import { MyProduct } from './myProduct';
import { Machine } from './machine';
import { ListedCultureSettingEntity } from './listedCultureSettingEntity';
import { Feedback } from './feedback';
import { Client } from './client';
import { ParcelTemplateEntity } from './parcelTemplateEntity';
import { Repair } from './repair';
import { Culture } from './culture';
import { FileEntity } from './fileEntity';
import { Purchase } from './purchase';
import { Parcel } from './parcel';
import { Calendar } from './calendar';
import { ApplicationMachineEntity } from './applicationMachineEntity';
import { CustomFertilizer } from './customFertilizer';
import { Herbicide } from './herbicide';
import { TrichogrammaPositionEntity } from './trichogrammaPositionEntity';
import { EnterpriseOption } from './enterpriseOption';
import { MachineRental } from './machineRental';
import { Note } from './note';
import { ApplicationNote } from './applicationNote';
import { Application } from './application';
import { Changeauth } from './changeauth';
import { TrichogrammaOrderEntity } from './trichogrammaOrderEntity';
import { Variety } from './variety';
import { MaxNutritionEntity } from './maxNutritionEntity';


export interface Enterprise { 
    cultures: Array<Culture>;
    id: number;
    smallinvoice: number | null;
    smallinvoiceRel: number | null;
    firstName: string;
    billingOption: Enterprise.BillingOptionEnum;
    lastName: string;
    gender: any;
    street: string;
    streetNumber: string;
    zip: number;
    city: string;
    canton: any;
    country: any;
    email: string;
    phoneNumber: string;
    enterpriseNumber: number | null;
    geoLocation: any;
    created: string;
    agencies: number;
    active: boolean;
    emailVerificated: boolean;
    newsletter: boolean;
    projectBe: boolean;
    verificationHash: string;
    apiToken: string;
    jdOauthToken: string | null;
    jdOauthTokenSecret: string | null;
    jdOauthRequest: string | null;
    jdOrganizationId: number | null;
    projectFibl: number | null;
    applicationNotes: Array<ApplicationNote>;
    applications: Array<Application>;
    calendars: Array<Calendar>;
    changeAuths: Array<Changeauth>;
    clients: Array<Client>;
    customFertilizers: Array<CustomFertilizer>;
    enterpriseOptions: EnterpriseOption;
    feedbacks: Array<Feedback>;
    herbicides: Herbicide;
    importProducts: Array<Purchase>;
    machineRentals: Array<MachineRental>;
    machines: Array<Machine>;
    maxNutritients: Array<MaxNutritionEntity>;
    myProducts: Array<MyProduct>;
    notes: Array<Note>;
    parcels: Array<Parcel>;
    repairs: Array<Repair>;
    users: Array<User>;
    varieties: Array<Variety>;
    licenses: Array<License>;
    files: Array<FileEntity>;
    colorMappings: Array<ListedCultureSettingEntity>;
    trichogrammaOrders: Array<TrichogrammaOrderEntity>;
    trichogrammaPositions: Array<TrichogrammaPositionEntity>;
    applicationMachines: Array<ApplicationMachineEntity>;
    parcelTemplates: Array<ParcelTemplateEntity>;
}
export namespace Enterprise {
    export type BillingOptionEnum = 'perMail' | 'perPost';
    export const BillingOptionEnum = {
        PerMail: 'perMail' as BillingOptionEnum,
        PerPost: 'perPost' as BillingOptionEnum
    };
}


