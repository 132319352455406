import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AccessInterceptor} from '../interceptors/access.interceptor';
import {MessageInterceptor} from '../interceptors/message.interceptor';
import {TSFormBuilder} from 'angular-type-safe-form';

@NgModule({
    declarations: [],
    providers: [
        TSFormBuilder,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: AccessInterceptor
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: MessageInterceptor
        },
    ],
    imports: [
        CommonModule
    ]
})
export class CoreModule {
}
