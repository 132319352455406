<div fusePerfectScrollbar fxFlex fxLayout="column">

    <mat-list cdkFocusRegionStart class="date">

        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12">
                Datum: {{date | date:'dd.MM.yyyy'}}
            </div>
            <!--<div fxLayout="row" fxLayoutAlign="start start">
                <span> {{date | date:'d'}}</span>
                <span class="mat-subheading-1">th</span>
                <span> {{date | date:'MMMM'}}</span>
            </div>-->
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-list fxFlex="grow" fxLayout="column">
        <h3 matSubheader>
            <span>Aufgaben - Heute</span>
        </h3>
        <div fxLayout="column" fxFlex="grow">
            <div fxFlex="grow">
                <div *ngFor="let app of tasksToday" fxLayoutGap="5px" class="task">
                    <div class="my-date">{{app[0]?.date | date: 'dd.MM.yyyy'}}</div>
                    <div class="type"><b>Typ: </b>{{app[0]?.applicationType.name}}</div>
                    <div fxLayout="" fxLayoutAlign="space-between">
                        <div class="product" *ngIf="app.culturesUnique.length">
                            <b>Kulturen: </b>{{app.culturesUnique.length}}
                        </div>
                        <div class="product" *ngIf="app.productsUnique.length">
                            <b>Produkte: </b>{{app.productsUnique.length}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between">
                        <a mat-button color="primary"
                           class="action"
                           *efHideOnReadOnly
                           matTooltip="Zur Anwendung"
                           [queryParams]="{type:app[0]?.applicationType.name }"
                           routerLink="/kultur/details/{{app[0].culture.id}}/{{app[0].groupId}}/bearbeiten">
                            <fa-icon [icon]="faMapAlt"></fa-icon>
                        </a>
                        <a mat-button color="primary"
                           class="action"
                           matTooltip="PDF generieren"
                           (click)="generatePdf(app)">
                            <fa-icon [icon]="faPdf"></fa-icon>
                        </a>
                        <button mat-button color="primary" matTooltip="Als erledigt markieren"
                                *efHideOnReadOnly
                                (click)="markAsChecked(app)">
                            <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="tasksToday.length === 0" class="text-center">
                    Keine offenen Aufgaben
                </div>
                <hr>

                <h3 matSubheader>
                    <span>Aufgaben - Morgen</span>
                </h3>


                <div *ngFor="let app of tasksTomorrow" fxLayoutGap="5px" class="task">
                    <div class="my-date">{{app[0]?.date | date: 'dd.MM.yyyy'}}</div>
                    <div class="type"><b>Typ: </b>{{app[0]?.applicationType.name}}</div>
                    <div fxLayout="" fxLayoutAlign="space-between">
                        <div class="product" *ngIf="app.culturesUnique.length">
                            <b>Kulturen: </b>{{app.culturesUnique.length}}
                        </div>
                        <div class="product" *ngIf="app.productsUnique.length">
                            <b>Produkte: </b>{{app.productsUnique.length}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between">
                        <a mat-button color="primary"
                           class="action"
                           *efHideOnReadOnly
                           matTooltip="Zur Anwendung"
                           [queryParams]="{
                               type: app[0].applicationType.name
                           }"
                           routerLink="/kultur/details/{{app[0].culture.id}}/{{app[0].groupId}}/bearbeiten">
                            <fa-icon [icon]="faMapAlt"></fa-icon>
                        </a>
                        <a mat-button color="primary"
                           class="action"
                           matTooltip="PDF generieren"
                           (click)="generatePdf(app)">
                            <fa-icon [icon]="faPdf"></fa-icon>
                        </a>
                        <button mat-button color="primary" matTooltip="Als erledigt markieren"
                                *efHideOnReadOnly
                                (click)="markAsCheckedTomorrow(app)">
                            <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="tasksTomorrow.length === 0" class="text-center">
                    Keine offenen Aufgaben
                </div>
            </div>
            <div class="weather">
                <h3 matSubheader>Wetter</h3>
                <div style="width:280px;height:388px" *ngIf="safeMeteoUrl">
                    <iframe scrolling="no" style="border:0;width:100%;height:100%"
                            [src]="safeMeteoUrl"></iframe>
                </div>
            </div>
        </div>


    </mat-list>

    <mat-divider></mat-divider>

    <!--<mat-list>
        <h3 matSubheader>
            <span>Notes</span>
        </h3>

        <mat-list-item *ngFor="let note of notes">
            <h3 matLine>{{note.title}}</h3>
            <p class="secondary-text" matLine>{{note.detail}}</p>
        </mat-list-item>
    </mat-list>-->

    <mat-divider></mat-divider>

    <!--<mat-list>
        <h3 matSubheader>
            <span>Quick Settings</span>
        </h3>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="settings.notify" aria-label="Notifications" class="mat-primary" fxFlex
                              labelPosition="before">
                <h3>Notifications</h3>
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="settings.cloud" aria-label="Cloud" class="mat-accent" fxFlex
                              labelPosition="before">
                <h3>Cloud Sync</h3>
            </mat-slide-toggle>
        </mat-list-item>

        <mat-list-item>
            <mat-slide-toggle [(ngModel)]="settings.retro" aria-label="Retro Thrusters" class="mat-warn" fxFlex
                              labelPosition="before">
                <h3>Retro Thrusters</h3>
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list>-->

</div>