import {Injectable} from '@angular/core';
import {Enterprise, NewsService} from '../../swagger';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import {NewsModalComponent} from './news-modal/news-modal.component';


@Injectable({
    providedIn: 'root'
})
export class NewsModalService {

    constructor(
        private newsService: NewsService,
        private matDialog: MatDialog
    ) {

    }

    public checkNews(currentUser: Enterprise) {
            this.newsService.getActiveNews().subscribe(news => {
                const messagesToDisplay = news.filter(newInformation => {
                    return (newInformation.userCreatedBefore === null || moment(currentUser.created).isBefore(newInformation.userCreatedBefore) || currentUser.id === 2 || currentUser.id === 2223) &&
                        newInformation.asModal && !localStorage.getItem(newInformation.closeCode || '');
                });
                if (messagesToDisplay.length) {
                    const matDialogRef = this.matDialog.open(NewsModalComponent);
                    matDialogRef.componentInstance.newsEntities = messagesToDisplay;
                }
            });
    }

}
