import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserStateService} from '../services/user-state.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor(
        private userState: UserStateService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let headers = request.headers;
        if (request.url.indexOf('localhost') > -1 ||
            request.url.indexOf('feldkalender') > -1) {
            if (sessionStorage.getItem('year')) {
                headers = headers.append('year', sessionStorage.getItem('year'));
            }
            if (localStorage.getItem('token')) {
                try {
                    jwtDecode(localStorage.getItem('token'));
                    headers = headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
                } catch (e) {
                    localStorage.removeItem('token');
                }
            }
        }
        return next.handle(request.clone({headers}));
    }
}
