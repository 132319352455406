import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormGroupComponent} from './form-group/form-group.component';
import {FormAttachmentDirective} from './form-attachment.directive';
import {InvalidControlScrollModule} from '../../directives/invalid-control-scroll/invalid-control-scroll.module';


@NgModule({
    declarations: [FormGroupComponent, FormAttachmentDirective],
    exports: [
        FormGroupComponent,
        InvalidControlScrollModule
    ],
    imports: [
        CommonModule,
        InvalidControlScrollModule
    ]
})
export class EfFormModule {
}
