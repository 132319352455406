import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Changeauth, ChangeAuthService, Enterprise, FileService, User, UserService} from '../swagger';
import {environment} from '../../environments/environment';
import {distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NewsModalService} from '../elements/news-modal/news-modal.service';
import * as moment from 'moment';
import {ModuleTypeEnum} from '../enums/module-type.enum';

@Injectable({
    providedIn: 'root'
})
export class UserStateService {
    private yearSubject: BehaviorSubject<number> = new BehaviorSubject<number>(+sessionStorage.getItem('year') || new Date().getFullYear());
    public yearObservable = this.yearSubject.asObservable();
    private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public userObservable: Observable<User> = this.userSubject.asObservable();
    private enterpriseSubject: BehaviorSubject<Enterprise> = new BehaviorSubject<Enterprise>(null);
    private fileCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public enterpriseObservable: Observable<Enterprise> = this.enterpriseSubject.asObservable();
    redirectionUrl: string;
    accessSubject: BehaviorSubject<Enterprise> = new BehaviorSubject<Enterprise>(null);
    private userEnterpriseReloadingObservable: Observable<any>;
    private isLoadingEnterpriseObservable = false;

    getFileCount() {
        return this.fileCountSubject.value;
    }

    getFileCountObservable() {
        return this.fileCountSubject.asObservable();
    }

    reloadFileCount() {
        this.fileService.getFileCount().subscribe(fileCount => this.fileCountSubject.next(fileCount.count));
    }


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public toastrService: ToastrService,
        public changeAuthService: ChangeAuthService,
        private newsModalService: NewsModalService,
        private fileService: FileService,
        private userService: UserService
    ) {
        this.enterpriseSubject.pipe(filter(v => !!v), map(value => e => e.id), distinctUntilChanged()).subscribe(val => {
            this.fileService.getFileCount().subscribe(fileCount => this.fileCountSubject.next(fileCount.count));
        });

        this.yearObservable.subscribe(year => {
            sessionStorage.setItem('year', year.toString());
        });
        this.enterpriseObservable.pipe(filter(val => !!val), take(1)).subscribe(enterprise => {
            this.newsModalService.checkNews(enterprise);
        });
    }


    setLastYear() {
        this.setYear(this.getYear() - 1);
    }

    getYear() {
        return this.yearSubject.value;
    }

    setYear(year: number) {
        this.yearSubject.next(year);
    }

    hasFinanceModule() {
        return this.getEnterprise().enterpriseOptions?.modulePrice > 0;
    }

    setYearAndReload(year: number) {
        const currentUrl = this.router.url.split('?')[0];
        this.setYear(year);
        if (this.router.url.indexOf('/dashboard') === -1) {
            return this.router.navigate(['/dashboard'], {
                queryParamsHandling: 'preserve'
            }).then(_ => {
                return this.router.navigate([currentUrl], {
                        queryParamsHandling: 'preserve'
                    }
                );
            });
        } else {
            return this.router.navigate(['/login'], {
                queryParamsHandling: 'preserve'
            }).then(_ => {
                return this.router.navigate([currentUrl], {
                        queryParamsHandling: 'preserve'
                    }
                );
            });
        }
    }

    reloadUserAndEnterprise() {
        if (this.isLoadingEnterpriseObservable === false) {
            this.isLoadingEnterpriseObservable = true;
            this.userEnterpriseReloadingObservable = this.userService.getUserAndEnterprise().pipe(
                tap((values: { user: User, enterprise: Enterprise, access?: Enterprise }) => {
                    this.userSubject.next(values.user);
                    this.enterpriseSubject.next(values.enterprise);
                    this.accessSubject.next(values.access);
                })
            );
        }

        this.userEnterpriseReloadingObservable.subscribe(val => {
            this.isLoadingEnterpriseObservable = false;
        });
        return this.userEnterpriseReloadingObservable;
    }

    getEnterprise() {
        return this.enterpriseSubject.value;
    }

    getAccess() {
        return this.accessSubject.value;
    }

    getUser() {
        return this.userSubject.value;
    }


    setCurrentYear() {
        this.yearSubject.next(new Date().getFullYear());
    }

    logout(url: string = '/auth/login') {
        this.userSubject.next(null);
        this.enterpriseSubject.next(null);
        this.accessSubject.next(null);
        localStorage.removeItem('token');
        localStorage.removeItem('foreignBusiness');
        this.router.navigateByUrl(url);
    }

    getUserAsync() {
        if (!this.userSubject.value) {
            return this.reloadUserAndEnterprise().pipe(
                map(values => values.user)
            ).toPromise();
        } else {
            return Promise.resolve(this.userSubject.value);
        }
    }

    getEnterpriseAsync(): Promise<Enterprise> {
        if (!this.enterpriseSubject.value) {
            return this.reloadUserAndEnterprise().pipe(
                map(values => values.user.enterprise)
            ).toPromise();
        } else {
            return Promise.resolve(this.userSubject.value.enterprise);
        }
    }

    getProfilePicture() {
        return `${environment.backend}/${this.getUser().profilePicture}`;
    }

    setNextYear() {
        this.setYear(this.getYear() + 1);
    }

    changeToBusiness(share: Changeauth) {
        this.changeAuthService.changeAuth({
            accessId: share.accessId,
            id: share.id
        }).subscribe(response => {
            this.toastrService.success('Betrieb erfolgreich gewechselt.', 'Erfolg');
            localStorage.setItem('token', response.token);
            this.reloadUserAndEnterprise().toPromise().then(_ => {
                this.setYear(new Date().getFullYear());
                this.router.navigate(['/dashboard']).then(_ => {

                });
            });
        });
    }

    changeToBusinessSuperAdmin(eId: number) {
        this.changeAuthService.changeAuthSuperAdmin({
            enterpriseId: eId
        }).subscribe(response => {
            this.toastrService.success('Betrieb erfolgreich gewechselt.', 'Erfolg');
            localStorage.setItem('token', response.token);
            this.reloadUserAndEnterprise().toPromise().then(_ => {
                this.setYear(new Date().getFullYear());
                this.router.navigate(['/dashboard']).then(_ => {

                });
            });
        });
    }

    shouldShowPrice() {
        const enterpriseOptions = this?.accessSubject?.value?.enterpriseOptions || this?.getEnterprise()?.enterpriseOptions;
        return enterpriseOptions?.modulePrice <= this.getYear() && enterpriseOptions?.modulePrice > 0;
    }

    isNotReadOnly() {
        return this.userSubject.value?.userrole !== User.UserroleEnum.ReadOnly;
    }

    isReadOnly() {
        return this.userSubject.value?.userrole === User.UserroleEnum.ReadOnly;
    }

    isFiblUser() {
        return this.getEnterprise().projectFibl;
    }

    isTestaccount() {
        return this.enterpriseSubject.value?.licenses?.length === 0 ||
            moment().isAfter(this.enterpriseSubject.value?.licenses[0]?.cancelled);
    }

    hasToBuyFileLicense() {
        return this.getFileCount() >= 10 &&
            !(
                this.enterpriseSubject.value.licenses.find(l => l.module === 2) &&
                (
                    moment().isBefore(this.enterpriseSubject.value.licenses.find(l => l.module === 2)?.cancelled) ||
                    this.enterpriseSubject.value.licenses.find(l => l.module === 2)?.cancelled === null
                )
            );
    }

    hasFileLicense() {
        return this.enterpriseSubject.value?.licenses.find(l => l.module === 2) &&
            (
                moment().isBefore(this.enterpriseSubject.value?.licenses.find(l => l.module === 2)?.cancelled) ||
                this.enterpriseSubject.value?.licenses.find(l => l.module === 2)?.cancelled === null
            );
    }

    getBasicLicense() {
        return this.enterpriseSubject.value?.licenses.find(l => l.module === ModuleTypeEnum.BASIC_MODULE);
    }

    willBasicLicenseBeRenewed() {
        return !this.enterpriseSubject.value.licenses.find(l => l.module === 1)?.cancelled;
    }
}
