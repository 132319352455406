import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {DemoComponent} from './pages/demo/demo.component';
import {ElementsModule} from './elements/elements.module';
import {CoreModule} from './modules/core.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import 'froala-editor/js/languages/de.js';
import {AddUserDto, ApiModule, Configuration} from './swagger';
import {ToastrModule} from 'ngx-toastr';
import {AuthGuard} from './guards/auth.guard';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/legacy-form-field';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FeedbackModule} from './dialogs/feedback/feedback.module';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {SharedRepairModule} from './pages/repair/shared-repair/shared-repair.module';

import {environment} from '../environments/environment';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {NewsModalModule} from './elements/news-modal/news-modal.module';
import {RoleGuard} from './guards/role.guard';
import {SuperAdminGuard} from './guards/super-admin-guard.service';
import {InactivityModule} from './dialogs/inactivity/inactivity.module';
import {FirebaseGuard} from './guards/firebase.guard';
import {DecimalPipe} from '@angular/common';
import {OAuthModule} from 'angular-oauth2-oidc';
import UserroleEnum = AddUserDto.UserroleEnum;
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {ContextMenuModule} from "@perfectmemory/ngx-contextmenu";


const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: 'demo',
        component: DemoComponent
    },
    {
        path: 'notizen',
        loadChildren: () => import('app/pages/notes/notes.module').then(m => m.NotesModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'unternehmen',
        loadChildren: () => import('app/pages/enterprises/enterprises.module').then(m => m.EnterprisesModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'hilfe',
        loadChildren: () => import('app/pages/help/help.module').then(m => m.HelpModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'version',
        loadChildren: () => import('app/pages/version/version.module').then(m => m.VersionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'john-deere',
        loadChildren: () => import('app/pages/john-deere/john-deere.module').then(m => m.JohnDeereModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'trichogramma',
        loadChildren: () => import('app/pages/trichogramma/trichogramma.module').then(m => m.TrichogrammaModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'news',
        loadChildren: () => import('app/pages/news/news.module').then(m => m.NewsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'benutzer',
        loadChildren: () => import('app/pages/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'reports',
        loadChildren: () => import('app/pages/report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'aufgaben',
        loadChildren: () => import('app/pages/task/task.module').then(m => m.TaskModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'maschine',
        loadChildren: () => import('app/pages/machine/machine.module').then(m => m.MachineModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'reparatur',
        loadChildren: () => import('app/pages/repair/repair.module').then(m => m.RepairModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'gelistete-kulturen',
        loadChildren: () => import('app/pages/listed-culture-setting/listed-culture-setting.module').then(m => m.ListedCultureSettingModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'mietuebersicht',
        loadChildren: () => import('app/pages/rental/rental.module').then(m => m.RentalModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'produkte/pflanzenschutz',
        loadChildren: () => import('app/pages/herbicide/herbicide.module').then(m => m.HerbicideModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'cultures',
        loadChildren: () => import('app/pages/cultures/cultures.module').then(m => m.CulturesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kunden',
        loadChildren: () => import('app/pages/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kultur',
        loadChildren: () => import('app/pages/cultures/cultures.module').then(m => m.CulturesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'schnittstelle',
        loadChildren: () => import('app/pages/interface/interface.module').then(m => m.InterfaceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'textvorlagen',
        loadChildren: () => import('app/pages/application-notes/application-note.module').then(m => m.ApplicationNoteModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'sorten',
        loadChildren: () => import('app/pages/variety/variety.module').then(m => m.VarietyModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'myproducts',
        loadChildren: () => import('app/pages/myproducts/myproducts.module').then(m => m.MyproductsModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'dateien',
        loadChildren: () => import('app/pages/file-management/file-management.module').then(m => m.FileManagementModule),
        canActivate: [
            AuthGuard,
            RoleGuard,
            FirebaseGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('app/pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [
            AuthGuard,
            SuperAdminGuard
        ]
    },
    {
        path: 'fibl',
        loadChildren: () => import('app/pages/fibl/fibl.module').then(m => m.FiblModule),
        canActivate: [
            AuthGuard,
        ]
    },
    {
        path: 'parcels',
        loadChildren: () => import('app/pages/parcels/parcels.module').then(m => m.ParcelsModule),
        canActivate: [
            AuthGuard,
            RoleGuard
        ],
        data: {
            roles: [UserroleEnum.Admin, UserroleEnum.Superadmin, UserroleEnum.ReadOnly]
        }
    },
    {
        path: 'auth',
        loadChildren: () => import('app/main/pages/authentication/authentication.module').then(module => module.AuthenticationModule),
    },
    {
        path: 'seite-nicht-gefunden',
        component: PageNotFoundComponent
    },
    {
        path: '**',
        redirectTo: 'seite-nicht-gefunden'
    }
];


const matRangeLabelIntl = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} von ${length}`;
};


export function MyPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = `Einträge pro Seite`;
    paginatorIntl.nextPageLabel = `Nächste Seite`;
    paginatorIntl.previousPageLabel = `Vorherige Seite`;
    paginatorIntl.getRangeLabel = matRangeLabelIntl;

    return paginatorIntl;
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

// @ts-ignore
@NgModule({
    declarations: [
        AppComponent,
        DemoComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        MatAutocompleteModule,
        CoreModule,
        SweetAlert2Module.forRoot(),
        BrowserAnimationsModule,
        OAuthModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        ApiModule.forRoot(() => new Configuration({basePath: environment.backend})),


        // Material moment date module
        MatMomentDateModule,
        FontAwesomeModule,
        // Material
        MatButtonModule,
        MatIconModule,

        ToastrModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FeedbackModule,
        SharedRepairModule,

        // App modules
        LayoutModule,

        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        // eFeldkalender Modules
        ElementsModule,
        NewsModalModule,
        InactivityModule,
        ContextMenuModule.forRoot()
    ],
    providers: [
        DecimalPipe,
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {
            provide: MatPaginatorIntl, useValue: MyPaginatorIntl()
        },
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
