import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserStateService} from '../../services/user-state.service';

@Directive({
    selector: '[efHideOnReadOnly]'
})
export class HideOnReadOnlyDirective {
    private created = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private userStateService: UserStateService
    ) {
        this.userStateService.userObservable.subscribe(user => {
            if (this.userStateService.isNotReadOnly()) {
                if (this.created === false) {
                    this.created = true;
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
                /*this.created = false;
                this.viewContainerRef.clear();*/
            } else {
                this.created = false;
                this.viewContainerRef.clear();
            }
        });
    }

}
