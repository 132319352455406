import {Compiler, Component, Injector, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ModalList} from '../../../main/modal-list';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Location} from '@angular/common';
import {ModalHelperService} from '../../../services/modal-helper.service';

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
    /**s
     * Constructor
     */
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private matDialog: MatDialog,
        private location: Location,
        private compiler: Compiler,
        private injector: Injector,
        private modalHelper: ModalHelperService
    ) {
        this.route.queryParams
            .pipe(
                map(queryParam => queryParam.modal),
                distinctUntilChanged(),
                filter(val => val),
            )
            .subscribe(val => {
                const foundModal = ModalList.find(modalEntry => modalEntry.name === val);
                (foundModal.load() as any).then(async entry => {
                    const options = {
                        maxWidth: this.route.snapshot.queryParams.maxWidth,
                        width: this.route.snapshot.queryParams.width,
                        ...this.route.snapshot.queryParams
                    };
                    const ref = this.matDialog.open(entry, options);
                    this.modalHelper.openModal(foundModal.type, options);
                    /*ref.afterClosed().subscribe(_ => {
                        if (this.route.snapshot.queryParams.modal === foundModal.name) {
                            this.router.navigate([], {
                                queryParams: {
                                    modal: null
                                },
                                queryParamsHandling: 'merge'
                            });
                        }
                    });*/
                });
            });


        this.router.events.pipe(
            filter(nav => nav instanceof NavigationEnd)
        ).subscribe((navEnd: NavigationEnd) => {
            if (navEnd.url.indexOf('openModal--') > -1) {
                const modal = navEnd.url.split('--')[1];
                this.location.back();
                setTimeout(() => {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {
                            modal
                        },
                        queryParamsHandling: 'merge'
                    });
                }, 100);
            }
        });
    }
}
