import {Component, OnInit} from '@angular/core';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import * as moment from 'moment';
import {AuthenticationService} from '../../../swagger';
import {UserStateService} from '../../../services/user-state.service';

@Component({
    selector: 'ef-logout-soon',
    templateUrl: './logout-soon.component.html',
    styleUrls: ['./logout-soon.component.scss']
})
export class LogoutSoonComponent implements OnInit {
    timeoutHandler: any;
    remainingTime: moment.Moment;
    intervalHandler: any;
    displayText: string;
    showSessionRunOut = false;

    constructor(
        private authService: AuthenticationService,
        private userStateService: UserStateService
    ) {
        this.checkSession();
        this.userStateService.userObservable.subscribe(val => this.checkSession());
    }

    private checkSession() {
        try {
            const token = localStorage.getItem('token');
            const tokenContent = jwtDecode(token) as JwtPayload;
            const expireDate = moment(tokenContent.exp * 1000);
            const msTillExpireMessage = expireDate.toDate().getTime() - moment().add(30, 'minutes').toDate().getTime();
            if (this.timeoutHandler) {
                clearTimeout(this.timeoutHandler);
            }
            this.timeoutHandler = setTimeout(() => {
                this.showSessionRunOut = true;
                if (this.intervalHandler) {
                    clearInterval(this.intervalHandler);
                }
                this.intervalHandler = setInterval(() => {
                    const duration = moment.duration(expireDate.toDate().getTime() - moment().toDate().getTime(), 'milliseconds');
                    this.displayText = duration.minutes() + ':' + duration.seconds();
                    if (duration.asSeconds() <= 0) {
                        this.userStateService.logout();
                        clearInterval(this.intervalHandler);
                        clearInterval(this.timeoutHandler);
                        this.showSessionRunOut = false;
                    }
                }, 1000);
            }, Math.min(Math.max(msTillExpireMessage || 0), 2147483647));
        } catch (e) {
            console.log(e);
        }
    }

    ngOnInit(): void {
    }

    renewSession() {
        this.showSessionRunOut = false;
        this.authService.refreshToken().subscribe(res => {
            localStorage.setItem('token', res.token);
            clearInterval(this.intervalHandler);
            clearInterval(this.timeoutHandler);
            this.checkSession();
        });
    }
}
