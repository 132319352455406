import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedbackComponent} from './feedback.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {EfFormModule} from '../../elements/ef-form/ef-form.module';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {FlexModule} from '@angular/flex-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {LoadingButtonModule} from '../../elements/loading-button/loading-button.module';


@NgModule({
    declarations: [
        FeedbackComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        ReactiveFormsModule,
        EfFormModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule,
        MatButtonModule,
        LoadingButtonModule
    ],
    exports: [
        FeedbackComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class FeedbackModule {
}
