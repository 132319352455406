import {Compiler, Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalList, ModalListEnum} from '../main/modal-list';
import {EfRouter} from '../helpers/ef-router.service';

@Injectable({
    providedIn: 'root'
})
export class ModalHelperService {
    constructor(
        private matDialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private efRouter: EfRouter,
    ) {
    }


    openModal<T = any>(modalEnum: ModalListEnum, options?: { maxWidth?: string | number, width?: string | number, [key: string]: any }): Promise<MatDialogRef<T>> {
        const modalFinding = ModalList.find(modalEntry => modalEntry.type === modalEnum);
        const additionalParams: any = {...{}, ...(options || {})};
        if (additionalParams) {
            delete additionalParams.maxWidth;
            delete additionalParams.width;
        }
        return this.router.navigate([], {
            queryParamsHandling: 'merge',
            queryParams: {
                modal: modalFinding.name,
                maxWidth: options?.maxWidth || '95%',
                width: options?.width || null,
                ...additionalParams
            }
        }).then(_ => {
            return (modalFinding.load() as any);
        }).then(entryComp => {
            const dialogRef = this.matDialog.openDialogs.find(dialog => dialog.componentInstance instanceof entryComp);
            dialogRef.afterClosed().subscribe(val => {
                this.removeParamsAfterClose(options, additionalParams, (modalFinding.paramsToRemove || []));
            });
            return dialogRef;
        });
    }

    private removeParamsAfterClose(options: { maxWidth?: string | number; width?: string | number; [p: string]: any }, additionalParams: any, paramsToRemove: string[] = []) {
        paramsToRemove.push('modal');
        paramsToRemove.push('maxWidth');
        paramsToRemove.push('width');
        if (this.matDialog.openDialogs.length === 0) {
            if (Object.keys(additionalParams).length > 0) {
                paramsToRemove.push(...Object.keys(additionalParams));
            }
            this.efRouter.removeQueryParams(...paramsToRemove);
        }
    }
}
