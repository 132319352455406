import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {NewsEntity} from '../../../swagger';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'ef-news-modal',
    templateUrl: './news-modal.component.html',
    styleUrls: ['./news-modal.component.scss']
})
export class NewsModalComponent implements OnInit {
    @Input() newsEntities: NewsEntity[];

    constructor(
        private matDialogRef: MatDialogRef<any>,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.newsEntities.forEach(entity => {
            (entity as any).sanitized = this.domSanitizer.bypassSecurityTrustHtml(entity.text);
        });
    }

    closeAndHide() {
        this.newsEntities.forEach(entity => {
            localStorage.setItem(entity.closeCode, new Date().getTime().toString());
        });
        this.matDialogRef.close();
    }

    close() {
        this.matDialogRef.close();
    }
}
