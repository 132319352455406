<div mat-dialog-title>
    <h2>Neuigkeiten</h2>
</div>
<div mat-dialog-content>
    <mat-accordion class="p-24 accordion-primary">
        <mat-expansion-panel [expanded]="first" *ngFor="let newsEntity of newsEntities; let first">
            <mat-expansion-panel-header>{{newsEntity.title}}</mat-expansion-panel-header>
            <div class="m-t-10" *ngIf="$any(newsEntity)?.sanitized">
                <div class="content-news-modal">
                    <div [innerHTML]="$any(newsEntity).sanitized"></div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutGap="10px">
    <button mat-button mat-raised-button color="primary" (click)="closeAndHide()">Schliessen & nicht mehr anzeigen
    </button>
    <button mat-button mat-raised-button (click)="close()">Schliessen</button>
</div>