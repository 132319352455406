<span mat-dialog-title>{{title || 'Bestätigung'}}</span>
<div mat-dialog-content>
    <div class="m-b-20">
        {{message}}
    </div>
</div>

<div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutGap="5px">
    <button mat-raised-button
            autofocus
            color="primary"
            (click)="confirm()"
            mat-button>
        {{successButtonText}}
    </button>
    <button mat-raised-button
            color="warn"
            (click)="cancel()"
            mat-button>
        {{cancelButtonText}}
    </button>
</div>