<div class="backdrop" *ngIf="showSessionRunOut">
    <div class="soon-logout-wrapper">
        <div class="soon-logout-header">
            Session läuft aus
        </div>
        <div class="soon-logout-body text-center">
            Ihre Session läuft bald aus. <br> <br>
            Klicken Sie auf <b>Session verlängern</b> um die Session zu verlängern. <br> <br>

            Sollten Sie die Session nicht in {{displayText}} Minuten verlängern, so werden sie automatisch abgemeldet.
        </div>
        <div class="soon-logout-action">
            <button class="renew-session" (click)="renewSession()">Session verlängern</button>
        </div>
    </div>
</div>
