import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldComponent} from './mat-form-field/mat-form-field.component';
import {FormErrorDirective} from './form-error.directive';
import {FormErrorComponent} from './form-error/form-error.component';
import { FormComponent } from './form/form.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { SearchResultComponent } from './search-result/search-result.component';
import {FlexModule} from '@angular/flex-layout';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        MatFormFieldComponent,
        FormErrorDirective,
        FormErrorComponent,
        FormComponent,
        SearchResultComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FlexModule,
        FontAwesomeModule,
        RouterModule,
        MatRadioModule,
        ReactiveFormsModule
    ],
    exports: [
        MatFormFieldComponent,
        FormErrorDirective,
        FormErrorComponent,
        FormComponent,
        SearchResultComponent
    ]
})
export class ElementsModule {
}
