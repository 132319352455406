import {
    ChangeDetectorRef,
    Component, ComponentFactoryResolver, ComponentRef,
    HostListener, Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';
import {User} from '../../../swagger/model/user';
import {SearchService} from '../../../services/search.service';
import {Router} from '@angular/router';
import {UserStateService} from '../../../services/user-state.service';
import {ModalHelperService} from '../../../services/modal-helper.service';
import {ModalListEnum} from '../../../main/modal-list';
import {EfRouter} from '../../../helpers/ef-router.service';
import {ToastrService} from 'ngx-toastr';
import {CookieBannerComponent} from '../../../elements/cookie-banner/cookie-banner.component';
import {faCookie, faCookieBite} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('cookieDisclaimerVcr', {read: ViewContainerRef}) cookieDisclaimerVcr: ViewContainerRef;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    // -----------------------------------------------------------------------------------------------------
    user$: Observable<User> = this.userState.userObservable;
    years: number[] = [];
    // Private
    private _unsubscribeAll: Subject<any>;
    cookieDisclaimerComponent: ComponentRef<CookieBannerComponent>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        public userState: UserStateService,
        private searchService: SearchService,
        private modalHelper: ModalHelperService,
        private router: Router,
        private toastrService: ToastrService,
        private factoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private cdr: ChangeDetectorRef
    ) {
        // Set the defaults

        for (let i = 2014; i <= new Date().getFullYear() + 2; i++) {
            this.years.push(i);
        }
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    @HostListener('keyup', ['$event']) onKeyUp($event: KeyboardEvent) {
        if ($event.code === 'escape') {
            //this.collapse();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    faCookie = faCookieBite;

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        if (typeof value === 'string') {
            this.searchService.searchTermChanged.next(value);
        }
    }



    logout() {
        this.userState.logout();
    }

    setProfilePicture() {
        this.modalHelper.openModal(ModalListEnum.setProfilePicture);
    }

    selectYear(year: number) {
        if (year !== this.userState.getYear()) {
            this.userState.setYearAndReload(year).then(_ => {
                this.toastrService.success('Jahr erfolgreich gesetzt', 'Erfolg');
            });
        }
    }

    adjustCookieSettings() {
        const resolver = this.factoryResolver.resolveComponentFactory(CookieBannerComponent);
        const cookieDisclaimerComponent = resolver.create(this.injector);
        this.cookieDisclaimerVcr.insert(cookieDisclaimerComponent.hostView);
        if (document) {
            if ((document.querySelector('.help-section') as HTMLElement)) {
                (document.querySelector('.help-section') as HTMLElement).style.display = 'none';
            }
        }
        cookieDisclaimerComponent.instance.saved.subscribe(val => {
            cookieDisclaimerComponent.instance.saved.unsubscribe();
            cookieDisclaimerComponent.destroy();
            this.cookieDisclaimerVcr.clear();
            if (document) {
                if ((document.querySelector('.help-section') as HTMLElement)) {
                    (document.querySelector('.help-section') as HTMLElement).style.display = 'inherit';
                }
            }
        });
    }
}
