/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateUserConfigDto { 
    key: CreateUserConfigDto.KeyEnum;
    value: string;
}
export namespace CreateUserConfigDto {
    export type KeyEnum = 'APPLICATION_AMOUNT_PREFERENCE' | 'RENT_MACHINES_IN_APPLICATION';
    export const KeyEnum = {
        ApplicationAmountPreference: 'APPLICATION_AMOUNT_PREFERENCE' as KeyEnum,
        RentMachinesInApplication: 'RENT_MACHINES_IN_APPLICATION' as KeyEnum
    };
}


