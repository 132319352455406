import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserStateService} from '../services/user-state.service';
import jwtDecode, {JwtPayload} from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {


    constructor(
        private router: Router,
        private userStateService: UserStateService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (localStorage.getItem('token')) {
            return new Promise((resolve) => {
                this.userStateService.getUserAsync().then(u => {
                    if (u) {
                        resolve(true);
                    } else {
                        this.userStateService.redirectionUrl = state.url;
                        this.router.navigateByUrl('/auth/login');
                        resolve(false);
                    }
                }).catch(err => {
                    this.router.navigateByUrl('/auth/login');
                    resolve(false);
                });
            });
        } else {
            this.userStateService.redirectionUrl = state.url;
            this.router.navigateByUrl('/auth/login');
            return false;
        }
    }

}
