import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserStateService} from '../../services/user-state.service';
import {FormGroupTyped, TSFormBuilder} from 'angular-type-safe-form';
import {User, UserService} from '../../swagger';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'ef-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
    showDetails = true;
    myFormGroup: FormGroupTyped<Partial<User>>;
    @Output('saved') saved: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private userStateService: UserStateService,
        private fb: TSFormBuilder,
        private userService: UserService,
        private toastrService: ToastrService
    ) {
        this.myFormGroup = this.fb.group<Partial<User>>({
            marketingCookies: [false],
            cookieAccepted: [new Date() as any]
        });
        this.myFormGroup.patchValue(this.userStateService.getUser());
    }

    ngOnInit(): void {
    }

    acceptAllAndSubmit() {
        this.myFormGroup.getSafe(form => form.marketingCookies).patchValue(true);
        this.saveCookies();
    }

    saveCookies() {
        this.userService.setCookies(this.myFormGroup.value as User).subscribe(val => {
            this.toastrService.success('Cookies wurden erfolgreich gesichert', 'Erfolg');
            this.userStateService.reloadUserAndEnterprise();
            this.saved.emit();
        });
    }
}
