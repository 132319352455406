import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminService } from './api/admin.service';
import { ApplicationService } from './api/application.service';
import { ApplicationNoteService } from './api/applicationNote.service';
import { ApplicationTypeService } from './api/applicationType.service';
import { AuthenticationService } from './api/authentication.service';
import { CalendarService } from './api/calendar.service';
import { CantonService } from './api/canton.service';
import { CantonGeodataRequestService } from './api/cantonGeodataRequest.service';
import { ChangeAuthService } from './api/changeAuth.service';
import { ColorService } from './api/color.service';
import { CountryService } from './api/country.service';
import { CultureService } from './api/culture.service';
import { CustomFertilizerService } from './api/customFertilizer.service';
import { CustomerService } from './api/customer.service';
import { DataControllerService } from './api/dataController.service';
import { DefaultService } from './api/default.service';
import { DiscountService } from './api/discount.service';
import { EnterpriseService } from './api/enterprise.service';
import { ExportsService } from './api/exports.service';
import { FavoriteService } from './api/favorite.service';
import { FeedbacksService } from './api/feedbacks.service';
import { FertilizersService } from './api/fertilizers.service';
import { FileService } from './api/file.service';
import { FirebaseService } from './api/firebase.service';
import { GenderService } from './api/gender.service';
import { GuidesService } from './api/guides.service';
import { HelpCategoriesService } from './api/helpCategories.service';
import { HerbicideService } from './api/herbicide.service';
import { HerbicideTypesService } from './api/herbicideTypes.service';
import { InvoiceService } from './api/invoice.service';
import { LicenseService } from './api/license.service';
import { ListedCultureService } from './api/listedCulture.service';
import { ListedCultureSettingService } from './api/listedCultureSetting.service';
import { ListedFertilizerService } from './api/listedFertilizer.service';
import { ListedHerbicideService } from './api/listedHerbicide.service';
import { MachineService } from './api/machine.service';
import { MachineRentalService } from './api/machineRental.service';
import { MailingService } from './api/mailing.service';
import { MaxNutritionsService } from './api/maxNutritions.service';
import { ModuleService } from './api/module.service';
import { NewsService } from './api/news.service';
import { NewsletterService } from './api/newsletter.service';
import { NoteService } from './api/note.service';
import { ParcelService } from './api/parcel.service';
import { ParcelTemplateService } from './api/parcelTemplate.service';
import { ProductsService } from './api/products.service';
import { PurchaseService } from './api/purchase.service';
import { RepairService } from './api/repair.service';
import { ReportService } from './api/report.service';
import { ReportFrontendService } from './api/reportFrontend.service';
import { RssFeedService } from './api/rssFeed.service';
import { SettingsService } from './api/settings.service';
import { TrichogrammaOrderService } from './api/trichogrammaOrder.service';
import { UsabilityService } from './api/usability.service';
import { UserService } from './api/user.service';
import { UserConfigService } from './api/userConfig.service';
import { VarietyService } from './api/variety.service';
import { VideosService } from './api/videos.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
