import {Injectable, TemplateRef} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter, startWith} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BuildingBlockService {
    private pageTitleChangedSubject: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
    public pageTitleChangedSubject$: Observable<TemplateRef<any>> = this.pageTitleChangedSubject.asObservable();
    private helpSectionChanged: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
    public helpSectionChanged$: Observable<TemplateRef<any>> = this.helpSectionChanged.asObservable();

    constructor(
        private router: Router
    ) {
       /* this.router.events.pipe(
            startWith(this.router.url),
            filter(nav => nav instanceof NavigationEnd),
        ).subscribe(val => {
            this.helpSectionChanged.next(null);
        });*/
    }

    setPageTitle(pageTitleRef: TemplateRef<any>) {
        this.pageTitleChangedSubject.next(pageTitleRef);
    }

    setHelpSection(helpSection: TemplateRef<any>) {
        this.helpSectionChanged.next(helpSection);
    }
}
