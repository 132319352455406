import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    constructor(
        private matDialogRef: MatDialog
    ) {
    }

    openDialog(message: string, title: string = 'Bestätigung', successButtonText: string = 'Speichern', cancelButtonText: string = 'Abbrechen', options?: any) {
        const ref = this.matDialogRef.open(ConfirmDialogComponent, {
            maxWidth: '90%',
            width: '400px',
            ...(options || {})
        });
        ref.componentInstance.message = message;
        ref.componentInstance.title = title;
        ref.componentInstance.successButtonText = successButtonText;
        ref.componentInstance.cancelButtonText = cancelButtonText;
        return ref.componentInstance.event.pipe(take(1)).toPromise();
    }

    openDeleteDialog(message: string, title: string = 'Bestätigung', successButtonText: string = 'Löschen', cancelButtonText: string = 'Abbrechen') {
        const ref = this.matDialogRef.open(ConfirmDialogComponent, {
            maxWidth: '90%',
            width: '400px'
        });
        ref.componentInstance.message = message;
        ref.componentInstance.title = title;
        ref.componentInstance.successButtonText = successButtonText;
        ref.componentInstance.cancelButtonText = cancelButtonText;
        return ref.componentInstance.event.pipe(take(1)).toPromise();
    }
}
