/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddUserDto { 
    firstName?: string;
    lastName?: string;
    userrole: AddUserDto.UserroleEnum;
    notes: string;
    password: string;
    email: string;
    shortTag: string;
}
export namespace AddUserDto {
    export type UserroleEnum = 'admin' | 'superadmin' | 'member' | 'deleted' | 'nologin' | 'read-only';
    export const UserroleEnum = {
        Admin: 'admin' as UserroleEnum,
        Superadmin: 'superadmin' as UserroleEnum,
        Member: 'member' as UserroleEnum,
        Deleted: 'deleted' as UserroleEnum,
        Nologin: 'nologin' as UserroleEnum,
        ReadOnly: 'read-only' as UserroleEnum
    };
}


