import {Inject, Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserStateService} from './user-state.service';
import {filter} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';


// Gemäss GUIDE https://jaxenter.de/google-analytics-angular-57919
@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    private active = false;
    private window = window;

    constructor(
        private router: Router,
        private userStateService: UserStateService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public trackPageView(page: string) {
        if (!this.active) {
            return;
        }
        (window as any).ga('set', 'page', page);
        (window as any).ga('send', 'pageview');
    }

    private initializeAnalytics() {
        if (!environment.analytics || !environment.analytics.trackingId) {
            return;
        }
        ((window, document, element, scriptPatch, functionName, a?, m?) => {
            window['GoogleAnalyticsObject'] = functionName;
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            window[functionName] = window[functionName] || function() {
                (window[functionName].q = window[functionName].q || []).push(arguments);
            }, window[functionName].l = 1 * (new Date() as any);
            a = document.createElement(element),
                m = document.getElementsByTagName(element)[0];
            a.async = 1;
            a.src = scriptPatch;
            m.parentNode.insertBefore(a, m);
        })(this.window, document, 'script', environment.analytics.debug ? '//www.google-analytics.com/analytics_debug.js' : '//www.google-analytics.com/analytics.js', 'ga');
        if (!environment.analytics.debug) {
            this.window['ga_debug'] = {trace: true};
        }
        if (!environment.analytics.cookieDomain) {
            (this.window as any).ga('create', environment.analytics.trackingId, {
                'cookieDomain': environment.analytics.cookieDomain
            });
        } else {
            (this.window as any).ga('create', environment.analytics.trackingId, 'auto');
        }
        this.active = true;
    }

    start() {
        this.window = this.document.defaultView;
        this.userStateService.userObservable
            .pipe(filter(u => !!u))
            .subscribe(user => {
                if (user.marketingCookies && this.active === false) {
                    this.initializeAnalytics();
                    this.router.events
                        .subscribe(event => {
                            if (event instanceof NavigationEnd) {
                                this.trackPageView(event.urlAfterRedirects);
                            }
                        });
                }
            });
    }
}
