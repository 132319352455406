import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideOnReadOnlyDirective } from './hide-on-read-only.directive';



@NgModule({
    declarations: [HideOnReadOnlyDirective],
    exports: [
        HideOnReadOnlyDirective
    ],
    imports: [
        CommonModule
    ]
})
export class HideOnReadOnlyModule { }
