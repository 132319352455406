import {Injectable} from '@angular/core';
import {FirebaseService} from '../swagger';
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AngularFireAuth} from "@angular/fire/compat/auth";

export interface FirebaseLoggedInUser {
    uId: string;
    enterpriseId: number;
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseAuthService {
    private isLoggedIn = false;
    private userInformation: FirebaseLoggedInUser;

    constructor(
        private firebaseService: FirebaseService,
        private storage: AngularFireStorage,
        private angularFireAuth: AngularFireAuth
    ) {

    }

    getIsLoggedIn() {
        return this.isLoggedIn;
    }

    logIn() {
        return new Promise<boolean>((resolve, reject) => {
            this.firebaseService.createFirebaseToken().subscribe(response => {
                this.angularFireAuth.signInWithCustomToken(response.token)
                    .then((userCredential) => {
                        this.isLoggedIn = true;
                        this.userInformation = {uId: userCredential.user.uid, enterpriseId: null};
                        resolve(true);
                    }).catch(err => {
                    resolve(false);
                });
            });
        });
    }
}
