<form [formGroup]="myFormGroup" (ngSubmit)="saveCookies()">
    <div>
        <h2>Diese Webapplikation verwendet Cookies</h2>
        <p>Wir verwenden Cookies um den Gebrauch unserer Webseite besser analysieren zu können.
            Des Weiteren hoffen wir Fehler möglichst früh zu erkennen. <br>
            Zudem benötigten wir die Cookies um zu sehen welche Browser verwendet werden und welche Versionen wir noch
            aktiv
            unterstützten sollten. </p>
        <p>Es handelt sich dabei um sogenannten Tracking und Analyse Cookies, welche durch die Library <b>Google -
            Analytics</b>
            zur Verfügung gestellt werden.</p>

        <!--  <b (click)="showDetails = !showDetails">
              <a mat-button>
                  <ng-container *ngIf="showDetails">
                      Details ausblenden
                  </ng-container>
                  <ng-container *ngIf="!showDetails">
                      Details anzeigen
                  </ng-container>
              </a>
          </b>-->

        <div *ngIf="showDetails" class="m-t-10 m-b-10">
            <mat-slide-toggle formControlName="marketingCookies">
                Analyse / Marketing - Cookies
            </mat-slide-toggle>
        </div>


        <div fxLayoutGap="10px"
             class="m-t-10"
             fxLayout.lt-sm="column">
            <button (click)="acceptAllAndSubmit()" type="button" mat-button mat-raised-button color="primary">
                Alle Cookies akzeptieren
            </button>
            <button mat-button mat-raised-button>
                Auswahl speichern
            </button>
        </div>
    </div>
</form>