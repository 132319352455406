import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectTypeaheadComponent } from './mat-select-typeahead/mat-select-typeahead.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
    declarations: [MatSelectTypeaheadComponent],
    exports: [
        MatSelectTypeaheadComponent
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        ReactiveFormsModule
    ]
})
export class MatSelectTypeaheadModule { }
