/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Parcel } from './parcel';
import { Culture } from './culture';


export interface ParcelAndCultureMigrationDto { 
    cultureEdits: Culture;
    parcelEdits: Parcel;
    editCulture: boolean;
    editParcel: boolean;
    parcelId: number;
    parcelSorting: number;
    cultureSorting: number;
    parcelName: string;
    parcelArea: number;
    parcelNotes: string;
    parentSorting: number;
    importParcel: boolean;
    importCulture: boolean;
    parcelHeader?: boolean;
    cultureName: string;
    cultureNotes: string;
    isParentImportable: boolean;
    cultureType: ParcelAndCultureMigrationDto.CultureTypeEnum;
    isNewCulture?: boolean;
    isNewParcel?: boolean;
    preCultureName: string;
    cultureVariety: string;
    cultureArea: number;
    cultureId: number;
    action: ParcelAndCultureMigrationDto.ActionEnum;
    parcelToCreate: Parcel;
    cultureToCreate: Culture;
}
export namespace ParcelAndCultureMigrationDto {
    export type CultureTypeEnum = 'mainculture' | 'secondculture' | 'premainculture' | 'subseed';
    export const CultureTypeEnum = {
        Mainculture: 'mainculture' as CultureTypeEnum,
        Secondculture: 'secondculture' as CultureTypeEnum,
        Premainculture: 'premainculture' as CultureTypeEnum,
        Subseed: 'subseed' as CultureTypeEnum
    };
    export type ActionEnum = 'MOVE' | 'COPY';
    export const ActionEnum = {
        Move: 'MOVE' as ActionEnum,
        Copy: 'COPY' as ActionEnum
    };
}


