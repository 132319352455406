import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {FirebaseAuthService} from '../services/firebase-auth.service';

@Injectable({
    providedIn: 'root'
})
export class FirebaseGuard implements CanActivate {


    constructor(
        private firebaseAuthService: FirebaseAuthService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.firebaseAuthService.getIsLoggedIn()) {
            return this.firebaseAuthService.logIn();
        } else {
            return true;
        }
    }

}
