<div fxLayout="column">

    <ng-container *ngIf="searchService.searchResult?.cultures.length">
        <div fxLayoutGap="10px" class="search-title">
            <fa-icon [icon]="faSeeding"></fa-icon>
            <span fxFlex="grow">Kulturen</span>
            <span>({{searchService.searchResult.cultures.length}})</span>
        </div>

        <a class="search-result" routerLink="/kultur/details/{{culture.id}}" *ngFor="let culture of searchService.searchResult.cultures">
            {{culture.listedCulture?.name}}
        </a>
    </ng-container>

    <ng-container *ngIf="searchService.searchResult?.parcels.length">
        <div fxLayoutGap="10px" class="search-title">
            <fa-icon [icon]="faTags"></fa-icon>
            <span fxFlex="grow">Parzellen</span>
            <span>({{searchService.searchResult.parcels.length}})</span>
        </div>

        <a class="search-result" routerLink="/parcels/culturesIndex/" *ngFor="let parcel of searchService.searchResult.parcels">
            {{parcel.name}}
        </a>
    </ng-container>

    <ng-container *ngIf="searchService.searchResult?.users.length">
        <div fxLayoutGap="10px" class="search-title">
            <fa-icon [icon]="faUsers"></fa-icon>
            <span fxFlex="grow">Mitarbeiter</span>
            <span>({{searchService.searchResult.users.length}})</span>
        </div>

        <a class="search-result" routerLink="/benutzer/liste/" *ngFor="let user of searchService.searchResult.users">
            {{user.firstName}} {{user.lastName}}
        </a>
    </ng-container>

    <ng-container *ngIf="searchService.searchResult?.fertilizers.length">
        <div fxLayoutGap="10px" class="search-title">
            <fa-icon [icon]="faFilter"></fa-icon>
            <span fxFlex="grow">Dünger</span>
            <span>({{searchService.searchResult.fertilizers.length}})</span>
        </div>

        <a class="search-result" routerLink="/myproducts/duenger" *ngFor="let fertilizer of searchService.searchResult.fertilizers">
            {{fertilizer.listedFertilizer?.name || fertilizer.customFertilizer?.name}}
        </a>
    </ng-container>

    <ng-container *ngIf="searchService.searchResult?.herbicides.length">
        <div fxLayoutGap="10px" class="search-title">
            <fa-icon [icon]="faPlant"></fa-icon>
            <span fxFlex="grow">Pflanzenschutzmittel</span>
            <span>({{searchService.searchResult.herbicides.length}})</span>
        </div>

        <a class="search-result" routerLink="/myproducts/duenger" *ngFor="let herbicide of searchService.searchResult.herbicides">
            {{herbicide.herbicide?.product}}
        </a>
    </ng-container>


</div>