import {Component, ViewEncapsulation} from '@angular/core';
import {UserStateService} from '../../../services/user-state.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Application, ApplicationService, Culture} from '../../../swagger';
import * as moment from 'moment';
import {filter, startWith} from 'rxjs/operators';
import {faCheck, faFilePdf, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import {TaskHelperService} from '../../../services/task-helper.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, combineLatest, interval} from 'rxjs';
import {PdfService} from '../../../services/pdf.service';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    safeMeteoUrl: SafeResourceUrl;
    tasksToday: Application[][] = [];
    tasksTomorrow: Application[][] = [];
    faMapAlt = faMapMarkerAlt;
    faCheck = faCheck;
    todayTasks: any;
    tomorrowTasks: any;
    reloadSubject = new BehaviorSubject(null);
    faPdf = faFilePdf;

    /**
     * Constructor
     */
    constructor(
        public userStateService: UserStateService,
        public domSanitizer: DomSanitizer,
        public applicationService: ApplicationService,
        public taskService: TaskHelperService,
        public toastrService: ToastrService,
        public pdfService: PdfService
    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
        combineLatest([
            this.userStateService.userObservable,
            this.reloadSubject,
            interval(30000).pipe(startWith(0))
        ])
            .pipe(filter(([u]) => !!u))
            .subscribe(([user]) => {
                this.applicationService.getMyApplicationsAsGroup(user.id, 'pending', new Date() as any, new Date() as any).subscribe(applications => {
                    this.todayTasks = applications;
                    this.tasksToday = Object.values(applications);
                    this.tasksToday.forEach(task => {
                        (task as any).sumArea = _.sum(_.uniqBy<Application>(task, 'culture.id').map(app => app.subarea || app.culture.area || 0));
                        (task as any).culturesUnique = _.uniqBy((task as any).map(app => {
                            return {
                                ...app.culture,
                                subarea: app.subarea,
                                sumArea: _.sum(_.uniqBy((task as any).map(app => app.subarea || app.culture.area), 'id'))
                            };
                        }), 'id');
                        (task as any).productsUnique = _.uniqBy((task as any).filter(app => app?.myProduct).map(app => {
                            return {
                                ...app.myProduct,
                                amount: _.sum((task as any)
                                    .filter(appToCheck => appToCheck.myProduct.id === app.myProduct.id)
                                    .map(filteredApp => (filteredApp.amount))
                                ),
                                perHa: app.perHa,
                                area: _.sum((task as any).map(app => (app.subarea || app.culture.area)))
                            };
                        }), 'id');
                        (task as any).parcels = _.uniqBy(task.map(app => app.culture as Culture), 'id').map((culture: any) => culture.parcel.name);
                        (task as any).comments = _.uniq(task.map(app => app.note)).join(', ');
                    });
                });
            });
        combineLatest([
            this.userStateService.userObservable,
            this.reloadSubject,
            interval(30000).pipe(startWith(0))
        ])
            .pipe(filter(([u]) => !!u))
            .subscribe(([user]) => {
                this.applicationService.getMyApplicationsAsGroup(user.id, 'pending',
                    moment().add(1, 'day').toDate() as any,
                    moment().add(1, 'day').toDate() as any
                ).subscribe(applications => {
                    this.tasksTomorrow = applications;
                    this.tasksTomorrow = Object.values(applications);
                    this.tasksTomorrow.forEach(task => {
                        (task as any).sumArea = _.sum(_.uniqBy<Application>(task, 'culture.id').map(app => app.subarea || app.culture.area || 0));
                        (task as any).culturesUnique = _.uniqBy((task as any).map(app => {
                            return {
                                ...app.culture,
                                subarea: app.subarea,
                                sumArea: _.sum(_.uniqBy((task as any).map(app => app.subarea || app.culture.area), 'id'))
                            };
                        }), 'id');
                        (task as any).productsUnique = _.uniqBy((task as any).filter(app => app?.myProduct).map(app => {
                            return {
                                ...app.myProduct,
                                amount: _.sum((task as any)
                                    .filter(appToCheck => appToCheck.myProduct.id === app.myProduct.id)
                                    .map(filteredApp => (filteredApp.amount))
                                ),
                                perHa: app.perHa,
                                area: _.sum((task as any).map(app => (app.subarea || app.culture.area)))
                            };
                        }), 'id');
                        (task as any).parcels = _.uniqBy(task.map(app => app.culture as Culture), 'id').map((culture: any) => culture.parcel.name);
                        (task as any).comments = _.uniq(task.map(app => app.note)).join(', ');
                    });
                });
            });

        this.userStateService.getEnterpriseAsync().then(val => {
            this.safeMeteoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://meteo.search.ch/widget/' + val.city + '?bgcolor=fafafa');
        });
    }

    async markAsChecked(app: Application[]) {
        this.taskService.finishAsGroup(app).then(res => {
            this.reloadSubject.next(null);
        });
    }

    async markAsCheckedTomorrow(app: Application[]) {
        this.taskService.finishAsGroup(app).then(res => {
            this.reloadSubject.next(null);
        });
    }

    generatePdf(app: Application[]) {
        this.pdfService.printTask(app, 'Offener Auftrag von ' + this.userStateService.getUser().shortTag);
    }
}
