import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'ef-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    @Output() event: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() title: string;
    @Input() message: string;
    @Input() successButtonText: string;
    @Input() cancelButtonText: string;

    constructor(
        private matDialogRef: MatDialogRef<any>
    ) {
    }

    ngOnInit(): void {
    }

    confirm() {
        this.event.emit(true);
        this.matDialogRef.close();
        this.event.complete();
    }

    cancel() {
        this.event.emit(false);
        this.matDialogRef.close();
        this.event.complete();
    }
}
