import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {UserStateService} from './user-state.service';
import {ListedCulture, PdfConfigurationDto} from '../swagger';
import {MyProductNutritionsDto} from '../swagger/model/myProductNutritionsDto';
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class PdfService {


    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastrService,
        private userStateService: UserStateService
    ) {
    }

    private informUser() {
        if (this.userStateService.getEnterprise().active) {
            this.toastrService.info('Die Erstellung hat begonnen. Dieser Vorgang kann einen Moment dauern. Die Erstellung geschieht im Hintergrund. Die Webseite kann weiter verwendet werden.', 'Information', {
                timeOut: 10000
            });
        }
    }

    private toPdfAndDownload(fileName: string, response: Blob) {
        saveAs(response, fileName);
    }

    public getMachines() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/machines`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Maschinen', response);
        });
    }

    public getCultureList() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/culture-list`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Kulturenliste', response);
        });
    }

    public getRepairs(queryParams: {
        startDate: string,
        endDate: string
    }) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/repairs`, {
            responseType: 'blob',
            params: queryParams
        }).subscribe((response) => {
            this.toPdfAndDownload('Reparaturen', response);
        });
    }

    public summary(pdfOptions: PdfConfigurationDto) {
        this.informUser();
        this.httpClient.post(`${environment.api}/report/pdf`, pdfOptions, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Dossier', response);
        });
    }

    public getNotes(yearStart: number, yearEnd: number) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/notes`, {
            responseType: 'blob', params: {
                yearStart: yearStart.toString(),
                yearEnd: yearEnd.toString()
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Notizen', response);
        });
    }

    public printTask(task: any, title?: string) {
        this.informUser();
        if (task[0] instanceof Array) {
            task.forEach(subtask => {
                subtask.push({
                    culturesUnique: subtask.culturesUnique,
                    productsUnique: subtask.productsUnique,
                    sumArea: subtask.sumArea,
                    comments: subtask.comments,
                    parcels: subtask.parcels
                });
            });
        } else {
            task.push({
                culturesUnique: task.culturesUnique,
                productsUnique: task.productsUnique,
                sumArea: task.sumArea,
                comments: task.comments,
                parcels: task.parcels
            });
        }

        this.httpClient.post(`${environment.api}/report/print-task`, task, {
            responseType: 'blob', params: {
                title
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Auftrag.pdf', response);
        });
    }

    public printTasks(task: any[], title?: string) {
        this.informUser();
        this.httpClient.post(`${environment.api}/report/print-task`, task, {
            responseType: 'blob', params: {
                title
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Aufträge.pdf', response);
        });
    }

    public getListedCultureMeasures(listedCulture: ListedCulture, exportType: string) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/listedCultureMeasures`, {
            responseType: 'blob',
            params: {listedCultureId: listedCulture.id.toString(), type: exportType}
        }).subscribe((response) => {
            this.toPdfAndDownload('Kulturauswertung_' + listedCulture.name, response);
        });
    }

    public getClients() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/clients`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Kundenübersicht', response);
        });
    }


    public productsFertilizer(perDate: Date) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/productsFertilizer`, {
            responseType: 'blob', params: {
                'per-date': moment(perDate).format('YYYY-MM-DD')
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Inventarliste_Dünger', response);
        });
    }

    public getCropRotationPlan() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/cropRotationPlan`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Fruchtfolgerapport', response);
        });
    }

    public getCultureDetails(cultureId: number) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/cultureSheetDetail`, {
            responseType: 'blob',
            params: {cultureId: cultureId.toString()}
        }).subscribe((response) => {
            this.toPdfAndDownload('Kulturenblatt', response);
        });
    }

    public getEmployees() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/employees`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Mitarbeiterverzeichnis', response);
        });
    }

    public getFertilizers() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/fertilizers`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Düngemittel', response);
        });
    }

    public getHerbicideReport() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/herbicide-raport`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Pflanzenschutzmittel', response);
        });
    }

    public getHerbicides() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/herbicides`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Pflanzenschutzmittel', response);
        });
    }


    public getCulturesheet() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/culturesheet`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Kulturenverzeichnis', response);
        });
    }

    public getFertilizerRaport() {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/fertilizer-raport`, {responseType: 'blob'}).subscribe((response) => {
            this.toPdfAndDownload('Journal Düngemitteleinsatz', response);
        });
    }

    public productUsage(myProduct: MyProductNutritionsDto) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/productUsage`, {
            responseType: 'blob', params: {
                myProductId: myProduct.id.toString()
            }
        }).subscribe((response) => {
            this.toPdfAndDownload(`Produkt_${myProduct.fertilizer?.name || myProduct.herbicide?.product}`, response);
        });
    }

    public getRentals(queryParams?: {
        machineId: number,
        clientId: number,
        startDate: Date,
        endDate: Date,
        type: 0 | 1,
        sortByColumn: string,
        sortDirection: 'asc' | 'desc' | null
    }) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/rentals`, {
            responseType: 'blob',
            params: JSON.parse(JSON.stringify(queryParams))
        }).subscribe((response) => {
            this.toPdfAndDownload('Maschienenvermietung/-miete', response);
        });
    }

    getMeasuresOfMachine(machineId: number, startDate?: Date, endDate?: Date) {
        this.informUser();
        if (!startDate) {
            startDate = moment(this.userStateService.getYear(), 'YYYY').startOf('year').toDate();
        }
        if (!endDate) {
            endDate = moment(this.userStateService.getYear(), 'YYYY').endOf('year').toDate();
        }
        this.httpClient.get(`${environment.api}/report/measuresPerMachine`, {
            responseType: 'blob', params: {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                machineId: machineId.toString()
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Massnahmen_' + machineId, response);
        });
    }

    productsHerbicide(perDate: Date) {
        this.informUser();
        this.httpClient.get(`${environment.api}/report/productsHerbicides`, {
            responseType: 'blob', params: {
                'per-date': moment(perDate).format('YYYY-MM-DD')
            }
        }).subscribe((response) => {
            this.toPdfAndDownload('Inventarliste_Pflanzenschutz', response);
        });
    }

    getInvoicePdf(invoiceId: number) {
        this.informUser();
        this.httpClient.get(`${environment.api}/invoice/${invoiceId}/pdf`, {
            responseType: 'blob',
            params: null
        }).subscribe(response => {
            this.toPdfAndDownload(`${invoiceId}_Rechnung`, response);
        });
    }

    getInvoicePdfAsSuperAdmin(invoiceId: number) {
        this.informUser();
        this.httpClient.get(`${environment.api}/invoice/${invoiceId}/pdf/super-admin`, {
            responseType: 'blob',
            params: null
        }).subscribe(response => {
            this.toPdfAndDownload(`${invoiceId}_Rechnung`, response);
        });
    }

}
