import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {navigation} from 'app/navigation/navigation';
import {PageTitleComponent} from '../../../building-blocks/page-title/page-title.component';
import {BuildingBlockService} from '../../../building-blocks/building-block.service';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {ChangeAuthService} from '../../../swagger';
import {ActivatedRoute, Router} from '@angular/router';
import {UserStateService} from '../../../services/user-state.service';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    @ViewChild(PageTitleComponent) pageTitleComponent: PageTitleComponent;
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public buildingBlockService: BuildingBlockService,
        public changeAuthService: ChangeAuthService,
        private route: ActivatedRoute,
        public router: Router,
        public userStateService: UserStateService
    ) {
        this.route.queryParams.pipe(
            filter(data => data.foreignBusiness)
        ).subscribe(business => {
        });

        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    faHome = faSignOutAlt;

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    backToHomeBusiness() {
        this.userStateService.setYear(new Date().getFullYear());
        this.changeAuthService.homeBusiness().subscribe(val => {
            localStorage.setItem('token', val.token);
            this.router.navigateByUrl('/auth/login');
        });
    }
}
