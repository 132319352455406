/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageDto { 
    duration: number;
    title: string;
    body: string;
    type: MessageDto.TypeEnum;
}
export namespace MessageDto {
    export type TypeEnum = 'success' | 'danger' | 'warning' | 'info';
    export const TypeEnum = {
        Success: 'success' as TypeEnum,
        Danger: 'danger' as TypeEnum,
        Warning: 'warning' as TypeEnum,
        Info: 'info' as TypeEnum
    };
}


