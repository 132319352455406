/**
 * eFeldkalender - APP - REST / API
 * eFeldkalender Documentation of API
 *
 * The version of the OpenAPI document: 0.0.1-BETA
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Favorite } from './favorite';
import { UserConfigEntity } from './userConfigEntity';
import { Application } from './application';


export interface User { 
    id: number;
    email: string;
    profilePicture: string;
    enterprise: any;
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    password: string;
    userrole: User.UserroleEnum;
    shortTag: string;
    notes: string | null;
    token: string | null;
    tokenLastDay: string | null;
    resetLink: string | null;
    startLink: string | null;
    lastLogin: string;
    active: boolean;
    applications: Array<Application>;
    cookieAccepted: string;
    marketingCookies: boolean;
    favorites: Array<Favorite>;
    configs: Array<UserConfigEntity>;
}
export namespace User {
    export type UserroleEnum = 'admin' | 'superadmin' | 'member' | 'deleted' | 'nologin' | 'read-only';
    export const UserroleEnum = {
        Admin: 'admin' as UserroleEnum,
        Superadmin: 'superadmin' as UserroleEnum,
        Member: 'member' as UserroleEnum,
        Deleted: 'deleted' as UserroleEnum,
        Nologin: 'nologin' as UserroleEnum,
        ReadOnly: 'read-only' as UserroleEnum
    };
}


