import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InvalidControlScrollDirective} from './invalid-control-scroll.directive';


@NgModule({
    declarations: [InvalidControlScrollDirective],
    imports: [
        CommonModule
    ],
    exports: [
        InvalidControlScrollDirective
    ]
})
export class InvalidControlScrollModule {
}
