import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BuildingBlockService} from '../building-block.service';

@Component({
    selector: '[ef-page-title]',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit, AfterViewInit {
    @ViewChild('pageTitle', {read: TemplateRef}) pageTitleRef: TemplateRef<any>;

    constructor(private buildingBlockService: BuildingBlockService) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.buildingBlockService.setPageTitle(this.pageTitleRef);
        });
    }


}
