import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {Application, ApplicationService} from '../swagger';
import {ToastrService} from 'ngx-toastr';
import {ConfirmDialogService} from '../elements/confirm-dialog/confirm-dialog.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class TaskHelperService {

    constructor(
        private applicationService: ApplicationService,
        private toastrService: ToastrService,
        private confirmService: ConfirmDialogService
    ) {
    }

    public markAsCompleted(element: { groupId: string, id: number }): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.applicationService.getApplicationsAsGroup(element.groupId).subscribe(applications => {
                if (applications.length > 1) {
                    Swal.fire({
                        title: 'Verbundener Auftrag abschliessen?',
                        icon: 'warning',
                        confirmButtonText: 'Nur diesen Eintrag abschliessen',
                        showCancelButton: true,
                        cancelButtonText: 'Gesamter Auftrag abschliessen',
                        html: `Der selektierte Auftrag ist für mehrere Kulturen angedacht oder beinhaltet mehrere Produkte. <br>  
Die Massnahme wurde für folgende Kulturen definiert: <br> <br> 
${applications.map(app => app.culture.listedCulture.name).join('<br>')}
<br> <br> Folgende Produkte wurden verwendet: <br>
${(applications.map(app => app.myProduct?.listedFertilizer?.name || app.myProduct?.customFertilizer?.name || app.myProduct?.herbicide?.product).join('<br>')) || '<br> Keine Produkte'}`
                    }).then(val => {
                        if (val.dismiss === Swal.DismissReason.cancel) {
                            this.handleNotSameDay(applications).then(markAsSameDay => {
                                Promise.all(applications.map(app => this.applicationService.completeApplication(app.id, markAsSameDay).toPromise())).then(val => {
                                    this.toastrService.success(`${applications.length} Massnahmen als erledigt markiert`, 'Erfolg');
                                    resolve(true);
                                });
                            });
                        } else if (!val.dismiss) {
                            this.handleNotSameDay(applications).then(markAsSameDay => {
                                this.applicationService.extractApplicationFromGroup(element.id).subscribe(val => {
                                    this.completeSingleTask(element.id, markAsSameDay).then(_ => {
                                        resolve(true);
                                    });
                                });
                            });
                        } else {
                            this.toastrService.error('Vorgang abgebrochen', 'Fehler');
                            resolve(false);
                        }
                    });
                } else {
                    this.confirmService.openDialog('Sind Sie sicher, dass Sie die Massnahme als erledigt markieren wollen', 'Sind Sie sicher?', 'Bestätigen').then(val => {
                        if (val) {
                            this.handleNotSameDay(applications).then(markAsSameDay => {
                                this.completeSingleTask(element.id, markAsSameDay).then(_ => {
                                    resolve(true);
                                });
                            });
                        }
                    });
                }
            });
        });
    }

    private completeSingleTask(applicationId: number, markAsSameDay?: boolean) {
        return new Promise((resolve, reject) => {
            this.applicationService.completeApplication(applicationId, markAsSameDay).subscribe(_ => {
                this.toastrService.success('Massnahme beendet');
                resolve(true);
            });
        });
    }

    public handleNotSameDay(applications: Array<Application>) {
        if (moment(applications[0].date).startOf('day').isSame(moment().startOf('day'))) {
            return Promise.resolve(true);
        } else {
            return this.confirmService.openDialog(
                `Ausführungsdatum ist der ${moment(applications[0].date).format('DD.MM.YYYY')}. Soll das Datum auf heute angepasst werden?`,
                `Datum auf ${moment().format('DD.MM.YYYY')} setzen`,
                'Datum auf Heute setzen',
                'Datum beibehalten', {
                    width: '600px'
                }).then(val => {
                return val;
            });
        }
    }

    async finishAsGroup(applications: Application[]) {
        let result;
        if (moment(applications[0].date).format('DDMMYYYY') !== moment().format('DDMMYYYY')) {
            result = await this.handleNotSameDay(applications);
        }
        return this.applicationService.completeApplication(applications[0].id, result || false).toPromise().then(val => {
            this.toastrService.success('Anwendung wurde erfolgreich beendet', 'Erfolg');
            return true;
        });
    }
}
