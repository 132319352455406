import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserStateService} from '../services/user-state.service';

@Injectable({
    providedIn: 'root'
})
export class EfRouter {


    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private userState: UserStateService
    ) {
    }


    removeModalQueryParams() {
        const queryParams = ['maxWidth'];
        const queryParamsObj = {...this.route.snapshot.queryParams};
        queryParams.forEach(qp => {
            if (queryParamsObj[qp] !== undefined) {
                delete queryParamsObj[qp];
            }
        });
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParamsObj
        });
    }


    removeAllQueryParams() {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {}
        });
    }

    removeQueryParams(...queryParams: string[]) {
        const queryParamsObj = {...this.route.snapshot.queryParams};
        queryParams.forEach(qp => {
            if (queryParamsObj[qp] !== undefined) {
                delete queryParamsObj[qp];
            }
        });
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParamsObj
        });
    }

    addQueryParam(queryParamObj: { [key: string]: any }) {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {...this.route.snapshot, ...queryParamObj}
        });
    }


}
