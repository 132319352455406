import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerTodayHeaderComponent} from './datepicker-today-header/datepicker-today-header.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import { DatepickerDayMonthHeaderComponent } from './datepicker-day-month-header/datepicker-day-month-header.component';


@NgModule({
    declarations: [DatepickerTodayHeaderComponent, DatepickerDayMonthHeaderComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        FontAwesomeModule,
        MatTooltipModule,
    ],
    providers: [
        MatDatepickerModule,
    ],
    exports: [
        DatepickerTodayHeaderComponent
    ]
})
export class DatepickerTodayHeaderModule {
}
