import {Component, OnInit} from '@angular/core';
import {SearchService} from '../../services/search.service';
import {faFilter, faSeedling, faTags, faUsers} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'ef-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
    faFilter = faFilter;
    faPlant = faFilter;
    faTags = faTags;
    faSeeding = faSeedling;
    faUsers = faUsers;

    constructor(
        public searchService: SearchService
    ) {
    }

    ngOnInit(): void {
    }

}
