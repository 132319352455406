import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EnterpriseService, SearchResultDto} from '../swagger';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public searchTermChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public searchResult: SearchResultDto = null;

    constructor(
        private enterpriseService: EnterpriseService
    ) {
        this.searchTermChanged.asObservable().subscribe(value => {
            if (value) {
                this.enterpriseService.search(value).subscribe(searchResult => {
                    this.searchResult = searchResult;
                });
            } else {
                this.searchResult = null;
            }
        });
    }
}
