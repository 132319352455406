import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserStateService} from '../services/user-state.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(
        private userStateService: UserStateService,
        private router: Router,
        private toastrService: ToastrService
    ) {
    }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles = route.data.roles;
        if (localStorage.getItem('token')) {
            return new Promise((resolve) => {
                this.userStateService.getUserAsync().then(u => {
                    if (u) {
                        if (roles.indexOf(u.userrole) > -1) {
                            resolve(true);
                        } else {
                            this.toastrService.error('Sie verfügen nicht über die nötigen Rechte, um diese Seite zu besuchen!', 'Fehler');
                            this.router.navigateByUrl('/dashboard');
                            resolve(false);
                        }
                    } else {
                        this.userStateService.reloadUserAndEnterprise().toPromise().then(u => {
                            if (u.user && (u.user.userrole === 'admin' || u.user.userrole === 'superadmin')) {
                                resolve(true);
                            } else {
                                this.toastrService.error('Sie verfügen nicht über die nötigen Rechte, um diese Seite zu besuchen!', 'Fehler');
                                this.router.navigateByUrl('/dashboard');
                                resolve(false);
                            }
                        });
                    }
                });
            });
        } else {
            return false;
        }
    }

}


