import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RepairFormComponent} from './repair-form/repair-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {EfFormModule} from '../../../elements/ef-form/ef-form.module';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {FlexModule} from '@angular/flex-layout';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatSelectTypeaheadModule} from '../../../elements/mat-select-typeahead/mat-select-typeahead.module';
import {DatepickerTodayHeaderModule} from '../../../elements/datepicker-today-header/datepicker-today-header.module';


@NgModule({
    declarations: [RepairFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        EfFormModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatSelectTypeaheadModule,
        DatepickerTodayHeaderModule
    ]
})
export class SharedRepairModule {
}
