<div [ngClass]="fuseConfig.layout.navbar.secondaryBackground" class="navbar-top">

    <div class="logo">
        <img class="logo-icon" src="./assets/favicon/android-icon-36x36.png">
        <span class="logo-text secondary-text">eFeldkalender</span>
    </div>

    <div class="buttons">

        <button (click)="toggleSidebarFolded()" class="toggle-sidebar-folded"
                fxHide.lt-lg mat-icon-button>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button (click)="toggleSidebarOpened()" class="toggle-sidebar-opened"
                fxHide.gt-md mat-icon-button>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     class="navbar-scroll-container" fusePerfectScrollbar>

    <div [ngClass]="fuseConfig.layout.navbar.secondaryBackground" class="user" fxLayout="column">

        <ng-container *ngIf="loggedInUser$ | async as user">
            <div class="h3 username">{{user.firstName}} {{user.lastName}}</div>
            <div class="h5 email hint-text mt-8">{{user.email}}</div>
            <div [ngClass]="fuseConfig.layout.navbar.primaryBackground" class="avatar-container"
                 [class.no-profile]="!user.profilePicture" (click)="setProfilePicture()">
                <img class="avatar" src="{{environment.backend}}/{{user.profilePicture}}" *ngIf="user.profilePicture">
                <ng-container *ngIf="!user.profilePicture">
                    {{user.shortTag}}
                </ng-container>
            </div>
        </ng-container>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
        <div class="h5 logged-in-at text-center" (click)="toHomeBusiness()"
             matTooltip="Klicken Sie hier, um zu ihrem Heimatbetrieb zurückzukehren"
             *ngIf="(userState.accessSubject | async) as accessEnterprise">
            <div>Angemeldet bei:
                <br> {{accessEnterprise.firstName}} {{(accessEnterprise).lastName}}
                ({{(accessEnterprise).city}})
            </div>
            <div>
                <fa-icon size="2x" [icon]="faSignOut"></fa-icon>
            </div>
        </div>
    </div>

</div>
